import { Paper } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import { baseUrl } from "../../../../services/config";
import { Divider } from "antd";
import StaffPayDetailPrint from "./StaffPayDetailPrint";
import ReactToPrint from "react-to-print";
import { useAlert } from "react-alert";
import { upperCase } from "lodash";
import { DownloadTableExcel } from "react-export-table-to-excel";

const StaffPayDetails = () => {
  // const loginAccount = JSON.parse(localStorage.getItem("userDetails")).role[0];
  let authtoken = localStorage.getItem("userToken");
  const [sessionData, setSessionData] = useState([]);
  const [selected, setSelected] = useState("Overall");
  const [yearId, setYearId] = useState("");
  const [employeeCode, setEmployee] = useState("");
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const componentRef = useRef(null);
  const tableRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");

  const [isVisible, setIsVisible] = useState(false);
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);
  const [sessionYear, setSessionYear] = useState("");
  const alert = useAlert();

  useEffect(() => {
    sessions();
    getSettingData();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setYearId(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onRadioButtonClick = (e) => {
    const value = e.target.value;

    setSelected(e.target.value);
    if (value == "Overall") {
    }
    if (value == "Session") {
    }
  };

  const handleclick = () => {
    setLoading(true);
    const data = {
      year_id: selected == "Overall" ? "" : yearId,
      employee_code: employeeCode,
    };
    fetch(`${baseUrl}/staff-pay-detail-report`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((resp) => {
        setLoading(false);
        setIsVisible(true);
        resp.json().then((data) => {
          if (data.metadata) {
            if (data.metadata.success) {
              console.log("data", data.payload);
              setReportData(data.payload);
            }
          }
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const dateChange = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const d = new Date(date);

    return `${monthNames[d.getMonth()]}-${d.getFullYear()}`;
  };
  const pageStyle = `
  @page {
    size: landscape !important;
}
`;

  const getFeeSum = (array) => {
    const sum = array.reduce((a, c) => a + c.keyValue, 0);

    // console.log()
    // let sum = 0;
    // array.forEach((element) => {
    //   var data=keyValue
    //   sum += parseInt(element.data);
    // });
    // return sum;
  };
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-sm-3">
            <input
              checked={selected == "Overall"}
              onChange={(e) => onRadioButtonClick(e)}
              defaultChecked
              type="radio"
              id="Overall"
              value="Overall"
            />
            <label for="Overall">Over All</label>
          </div>

          <div className="col-sm-3">
            <input
              checked={selected == "Session"}
              onChange={(e) => onRadioButtonClick(e)}
              type="radio"
              id="Session"
              value="Session"
            />
            <label for="Session">Session Wise</label>
          </div>
        </div>
        <div className="row">
          {selected == "Session" ? (
            <div className="col-md-3 mt-5">
              <label>
                <b>Select Session</b>
              </label>
              <div className="d-flex flex-column ">
                <select
                  style={{ height: 40 }}
                  className="form-select"
                  name="year_id"
                  value={yearId}
                  aria-label="Default select example"
                  onChange={(e) => setYearId(e.target.value)}
                >
                  <option value="">Select Session</option>
                  {sessionData &&
                    sessionData.map((item) => (
                      <option value={item.id}>{item.year}</option>
                    ))}
                </select>
              </div>
            </div>
          ) : null}
          <div className="col-md-3 mt-5">
            <label>
              <b>Enter Employee Code</b>
            </label>
            <input
              className="form-control"
              placeholder="Enter Employee Code"
              onChange={(e) => setEmployee(e.target.value)}
            />
          </div>
          <div className="col-md-4 mt-14">
            <button
              disabled={loading}
              className="btn btn-primary mx-2"
              onClick={handleclick}
            >
              Search
            </button>
            {reportData.length > 0 ? (
              <>
                <ReactToPrint
                  pageStyle={pageStyle}
                  documentTitle={`Staff Pay Detail Report`}
                  onAfterPrint={handleAfterPrint}
                  onBeforeGetContent={handleOnBeforeGetContent}
                  onBeforePrint={handleBeforePrint}
                  removeAfterPrint={true}
                  trigger={() => (
                    <button
                      disabled={printloading ? true : false}
                      className="btn btn-primary w-100px mx-2"
                    >
                      {printloading ? <span>Printing..</span> : "Print"}
                    </button>
                  )}
                  content={() => componentRef.current}
                />

                <DownloadTableExcel
                  filename="Staff Pay Detail"
                  sheet="StaffPayDetail"
                  currentTableRef={tableRef.current}
                >
                  <button className="btn btn-primary mx-2"> Export </button>
                </DownloadTableExcel>
              </>
            ) : null}
          </div>
        </div>

        {loading ? (
          <div
            className="mt-5"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner variant="info" animation="border" />
          </div>
        ) : null}

        {isVisible ? (
          <>
            <div className="row p-3 mt-8">
              <img style={{ height: 100, width: 110 }} src={icon} />

              <div className="col-md-10">
                <h1>{unitName}</h1>
                <h5>Staff Pay Details</h5>
              </div>
            </div>

            <div>
              <div className="d-flex justify-content-between col-md-10">
                <div className="d-flex">
                  <h5>Staff Code: </h5> &nbsp;&nbsp;{" "}
                  <h6>{reportData[0]?.employee?.emp_code}</h6>
                </div>
                <div className="d-flex">
                  <h5>Name: </h5> &nbsp;&nbsp;
                  <h6>{reportData[0]?.employee?.full_name}</h6>
                </div>
                <div className="d-flex">
                  <h5>Account No: </h5> &nbsp;&nbsp;{" "}
                  <h6>{reportData[0]?.employee?.account_no}</h6>
                </div>
              </div>

              <div className="d-flex justify-content-between col-md-10">
                <div className="d-flex">
                  <h5>Joining Date:</h5> &nbsp;&nbsp;{" "}
                  <h6>{reportData[0]?.employee?.joining_date}</h6>
                </div>
                <div className="d-flex">
                  <h5>Designation: </h5> &nbsp;&nbsp;
                  <h6>{reportData[0]?.employee?.designation.name}</h6>
                </div>
                <div className="d-flex">
                  <h5>Campus: </h5> &nbsp;&nbsp;{" "}
                  <h6>{reportData[0]?.campus?.name}</h6>
                </div>
              </div>

              <div id="tableboot">
                <div className="makeITScrollAAble">
                  <Table
                    style={{
                      width: "350%",
                      marginTop: "15px",
                      overflowY: "scroll",
                    }}
                    responsive
                  >
                    <thead>
                      <tr id="tbl" style={{ position: "relative" }}>
                        <th className="text-center"></th>
                        <th className="text-center"></th>
                        <th className="text-center"></th>
                        <th className="text-center"></th>
                        <th className="text-center"></th>
                        <th className="text-center"></th>
                        <th className="text-center"></th>
                        <th className="text-center"></th>
                        <th className="text-center"></th>
                        <th className="text-center"></th>
                        <th
                          className="text-center text-primary"
                          style={{
                            position: "absolute",
                            width: "550px",
                            right: "45%",
                            bottom: "-18%",
                            borderBottom: "2px solid purple",
                          }}
                        >
                          ALLOWANCES
                        </th>
                        <th className="text-center"></th>
                        <th className="text-center"></th>
                        <th className="text-center"></th>
                        <th className="text-center"></th>
                        <th className="text-center"></th>
                        <th className="text-center"></th>
                        <th className="text-center"></th>
                        <th className="text-center"></th>
                        <th className="text-center"></th>
                        <th className="text-center"></th>
                        <th className="text-center"></th>
                        <th className="text-center"></th>
                        <th className="text-center"></th>
                        <th className="text-center"></th>
                        <th className="text-center"></th>
                        <th className="text-center"></th>
                        <th
                          className="text-center text-primary"
                          style={{
                            position: "absolute",
                            width: "550px",
                            right: "5%",
                            bottom: "-18%",
                            borderBottom: "2px solid purple",
                          }}
                        >
                          DEDUCTIONS
                        </th>
                        <th className="text-center"></th>
                        <th className="text-center"></th>
                        <th className="text-center"></th>
                      </tr>
                      <tr
                        id="tbl"
                        style={{
                          position: "sticky",
                          top: "0",
                          zIndex: "2",
                          backgroundColor: "gainsboro",
                        }}
                      >
                        <th className="text-center">Month</th>
                        <th className="text-center">Salary Days</th>
                        <th className="text-center">Bank</th>
                        <th className="text-center">Pay Scale</th>
                        <th className="text-center">Basic Pay</th>
                        <th className="text-center">Voucher</th>
                        <th className="text-center">Increment Total</th>
                        <th className="text-center">Eobi</th>
                        <th className="text-center">Eobi Payment</th>
                        <th className="text-center">UGS Allowance</th>
                        <th className="text-center">Additional Allowance</th>
                        <th className="text-center">Extra Period Allowance</th>
                        <th className="text-center">Extra Coaching</th>
                        <th className="text-center">2nd Shift Allowance</th>
                        <th className="text-center">HOD Allowance</th>
                        <th className="text-center">College Allowance</th>
                        <th className="text-center">Hostel Allowance</th>
                        <th className="text-center">Hifz Allowance</th>
                        <th className="text-center">Other Allowance</th>
                        <th className="text-center">Conv Allow</th>
                        <th className="text-center">Science</th>
                        <th className="text-center">GPF Return</th>
                        <th className="text-center">GP Contribution</th>
                        <th
                          className="text-center"
                          style={{
                            borderLeft: "1px solid black",
                            borderRight: "1px solid black",
                          }}
                        >
                          Gross Salary
                        </th>
                        <th className="text-center">GP Fund</th>
                        <th className="text-center">Loan Refund</th>
                        <th className="text-center">Insu Deduction</th>
                        <th className="text-center">Income Tax</th>
                        <th className="text-center">Staff Van Deduction</th>
                        <th className="text-center">Child Fee Deduction</th>
                        <th className="text-center">Welfare Fund</th>
                        <th className="text-center">Other Deduction</th>
                        <th className="text-center">Net Salary</th>
                      </tr>
                    </thead>

                    <tbody>
                      {reportData.map((subitem, index) => (
                        <tr key={index} id="tbl">
                          <td
                            style={{ color: "black" }}
                            className="text_align_center"
                          >
                            {dateChange(subitem?.salary_month)}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_center"
                          >
                            {subitem?.days}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_center"
                          >
                            {subitem?.bank_account?.bank_name} (
                            {subitem?.bank_account?.account_head})
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_center"
                          >
                            {subitem?.pay_scale == null
                              ? subitem?.employee?.pay_scale?.payscale
                              : subitem?.pay_scale?.payscale}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_center"
                          >
                            {subitem?.basic_pay}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_center"
                          >
                            {subitem?.voucher?.voucher_no}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_center"
                          >
                            {subitem?.increment}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_center"
                          >
                            {subitem?.eobi}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_center"
                          >
                            {subitem?.eobi_payments}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_center"
                          >
                            {subitem?.ugs}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_center"
                          >
                            {subitem?.additional_allowance}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_center"
                          >
                            {subitem?.extra_period}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_center"
                          >
                            {subitem?.extra_coaching}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_center"
                          >
                            {subitem?.second_shift}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_center"
                          >
                            {subitem?.hod}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_center"
                          >
                            {subitem?.college}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_center"
                          >
                            {subitem?.hostel}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_center"
                          >
                            {subitem?.hifz}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_center"
                          >
                            {subitem?.other_allowance}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_center"
                          >
                            {subitem?.convance}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_center"
                          >
                            {subitem?.science}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_center"
                          >
                            {subitem?.gpf_return}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_center"
                          >
                            {subitem?.gpf_cont}
                          </td>
                          <td
                            style={{
                              color: "black",
                              borderLeft: "1px solid black",
                              borderRight: "1px solid black",
                              backgroundColor: "beige",
                            }}
                            className="text_align_center"
                          >
                            {Number(subitem?.gross_salary)}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_center"
                          >
                            {subitem?.gp_fund}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_center"
                          >
                            {subitem?.loan_refund}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_center"
                          >
                            {subitem?.insurance}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_center"
                          >
                            {subitem?.income_tax}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_center"
                          >
                            {subitem?.van_charge}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_center"
                          >
                            {subitem?.child_fee_deduction}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_center"
                          >
                            {subitem?.welfare_fund}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_center"
                          >
                            {subitem?.other_deduction}
                          </td>
                          <td
                            style={{ color: "black" }}
                            className="text_align_center"
                          >
                            {Number(subitem?.net_pay)}
                          </td>
                        </tr>
                      ))}
                      <tr
                        id="tbl"
                        style={{
                          borderTop: "2px solid black",
                          borderBottom: "2px solid black",
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      >
                        <td
                          style={{ fontSize: "1.2rem" }}
                          className="text_align_center text-primary"
                        >
                          Totals
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {"-"}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {"-"}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {"-"}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.basic_pay),
                            0
                          )}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {"-"}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.increment),
                            0
                          )}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {reportData.reduce((a, c) => a + parseInt(c.eobi), 0)}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.eobi_payments),
                            0
                          )}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {reportData.reduce((a, c) => a + parseInt(c.ugs), 0)}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.additional_allowance),
                            0
                          )}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.extra_period),
                            0
                          )}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.extra_coaching),
                            0
                          )}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.second_shift),
                            0
                          )}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {reportData.reduce((a, c) => a + parseInt(c.hod), 0)}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.college),
                            0
                          )}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.hostel),
                            0
                          )}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {reportData.reduce((a, c) => a + parseInt(c.hifz), 0)}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.other_allowance),
                            0
                          )}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.convance),
                            0
                          )}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.science),
                            0
                          )}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.gpf_return),
                            0
                          )}
                        </td>

                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.gpf_cont),
                            0
                          )}
                        </td>
                        <td
                          style={{
                            color: "black",
                            borderLeft: "1px solid black",
                            borderRight: "1px solid black",
                            backgroundColor: "beige",
                          }}
                          className="text_align_center"
                        >
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.gross_salary),
                            0
                          )}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.gp_fund),
                            0
                          )}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.loan_refund),
                            0
                          )}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.insurance),
                            0
                          )}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.income_tax),
                            0
                          )}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.van_charge),
                            0
                          )}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.child_fee_deduction),
                            0
                          )}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.welfare_fund),
                            0
                          )}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.other_deduction),
                            0
                          )}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_center"
                        >
                          {reportData.reduce(
                            (a, c) => a + parseInt(c.net_pay),
                            0
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </Paper>

      <div
        ref={componentRef}
        id="printSection"
        style={{ visibility: "hidden" }}
      >
        {reportData.length > 0 ? (
          <StaffPayDetailPrint
            reportData={reportData}
            unitName={unitName}
            icon={icon}
          />
        ) : null}
      </div>

      {/* For Export */}
      <div ref={tableRef} id="printSection" style={{ visibility: "hidden" }}>
        <Table ref={tableRef} style={{ display: "none" }}>
          <thead>
            <tr id="tbl" style={{ position: "relative" }}>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
            </tr>
            <tr
              id="tbl"
              style={{
                position: "sticky",
                top: "0",
                zIndex: "2",
                backgroundColor: "gainsboro",
              }}
            >
              <th className="text-center">Month</th>
              <th className="text-center">Salary Days</th>
              <th className="text-center">Bank</th>
              <th className="text-center">Pay Scale</th>
              <th className="text-center">Basic Pay</th>
              <th className="text-center">Voucher</th>
              <th className="text-center">Increment Total</th>
              <th className="text-center">Eobi</th>
              <th className="text-center">Eobi Payment</th>
              <th className="text-center">UGS Allowance</th>
              <th className="text-center">Additional Allowance</th>
              <th className="text-center">Extra Period Allowance</th>
              <th className="text-center">Extra Coaching</th>
              <th className="text-center">2nd Shift Allowance</th>
              <th className="text-center">HOD Allowance</th>
              <th className="text-center">College Allowance</th>
              <th className="text-center">Hostel Allowance</th>
              <th className="text-center">Hifz Allowance</th>
              <th className="text-center">Other Allowance</th>
              <th className="text-center">Conv Allow</th>
              <th className="text-center">Science</th>
              <th className="text-center">GPF Return</th>
              <th className="text-center">GP Contribution</th>
              <th
                className="text-center"
                style={{
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                Gross Salary
              </th>
              <th className="text-center">GP Fund</th>
              <th className="text-center">Loan Refund</th>
              <th className="text-center">Insu Deduction</th>
              <th className="text-center">Income Tax</th>
              <th className="text-center">Staff Van Deduction</th>
              <th className="text-center">Child Fee Deduction</th>
              <th className="text-center">Welfare Fund</th>
              <th className="text-center">Other Deduction</th>
              <th className="text-center">Net Salary</th>
            </tr>
          </thead>

          <tbody>
            {reportData.map((subitem, index) => (
              <tr key={index} id="tbl">
                <td style={{ color: "black" }} className="text_align_center">
                  {dateChange(subitem?.salary_month)}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem?.days}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem?.bank_account?.bank_name} (
                  {subitem?.bank_account?.account_head})
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem?.pay_scale == null
                    ? subitem?.employee?.pay_scale?.payscale
                    : subitem?.pay_scale?.payscale}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem?.basic_pay}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem?.voucher?.voucher_no}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem?.increment}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem?.eobi}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem?.eobi_payments}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem?.ugs}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem?.additional_allowance}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem?.extra_period}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem?.extra_coaching}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem?.second_shift}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem?.hod}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem?.college}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem?.hostel}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem?.hifz}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem?.other_allowance}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem?.convance}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem?.science}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem?.gpf_return}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem?.gpf_cont}
                </td>
                <td
                  style={{
                    color: "black",
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black",
                    backgroundColor: "beige",
                  }}
                  className="text_align_center"
                >
                  {Number(subitem?.gross_salary)}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem?.gp_fund}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem?.loan_refund}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem?.insurance}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem?.income_tax}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem?.van_charge}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem?.child_fee_deduction}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem?.welfare_fund}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem?.other_deduction}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {Number(subitem?.net_pay)}
                </td>
              </tr>
            ))}
            <tr
              id="tbl"
              style={{
                borderTop: "2px solid black",
                borderBottom: "2px solid black",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              <td
                style={{ fontSize: "1.2rem" }}
                className="text_align_center text-primary"
              >
                Totals
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {"-"}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {"-"}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {"-"}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {reportData.reduce((a, c) => a + parseInt(c.basic_pay), 0)}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {"-"}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {reportData.reduce((a, c) => a + parseInt(c.increment), 0)}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {reportData.reduce((a, c) => a + parseInt(c.eobi), 0)}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {reportData.reduce((a, c) => a + parseInt(c.eobi_payments), 0)}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {reportData.reduce((a, c) => a + parseInt(c.ugs), 0)}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {reportData.reduce(
                  (a, c) => a + parseInt(c.additional_allowance),
                  0
                )}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {reportData.reduce((a, c) => a + parseInt(c.extra_period), 0)}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {reportData.reduce((a, c) => a + parseInt(c.extra_coaching), 0)}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {reportData.reduce((a, c) => a + parseInt(c.second_shift), 0)}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {reportData.reduce((a, c) => a + parseInt(c.hod), 0)}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {reportData.reduce((a, c) => a + parseInt(c.college), 0)}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {reportData.reduce((a, c) => a + parseInt(c.hostel), 0)}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {reportData.reduce((a, c) => a + parseInt(c.hifz), 0)}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {reportData.reduce(
                  (a, c) => a + parseInt(c.other_allowance),
                  0
                )}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {reportData.reduce((a, c) => a + parseInt(c.convance), 0)}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {reportData.reduce((a, c) => a + parseInt(c.science), 0)}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {reportData.reduce((a, c) => a + parseInt(c.gpf_return), 0)}
              </td>

              <td style={{ color: "black" }} className="text_align_center">
                {reportData.reduce((a, c) => a + parseInt(c.gpf_cont), 0)}
              </td>
              <td
                style={{
                  color: "black",
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                  backgroundColor: "beige",
                }}
                className="text_align_center"
              >
                {reportData.reduce((a, c) => a + parseInt(c.gross_salary), 0)}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {reportData.reduce((a, c) => a + parseInt(c.gp_fund), 0)}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {reportData.reduce((a, c) => a + parseInt(c.loan_refund), 0)}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {reportData.reduce((a, c) => a + parseInt(c.insurance), 0)}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {reportData.reduce((a, c) => a + parseInt(c.income_tax), 0)}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {reportData.reduce((a, c) => a + parseInt(c.van_charge), 0)}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {reportData.reduce(
                  (a, c) => a + parseInt(c.child_fee_deduction),
                  0
                )}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {reportData.reduce((a, c) => a + parseInt(c.welfare_fund), 0)}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {reportData.reduce(
                  (a, c) => a + parseInt(c.other_deduction),
                  0
                )}
              </td>
              <td style={{ color: "black" }} className="text_align_center">
                {reportData.reduce((a, c) => a + parseInt(c.net_pay), 0)}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default StaffPayDetails;
