import React, { useState, useEffect } from "react";
import { baseUrl } from "../../../services/config";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import SVG from "react-inlinesvg";
import { Table, Modal, Alert, Spinner } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import Pagination from "@material-ui/lab/Pagination";
import usePagination from "../../../components/usePagination/usePagination";
import { MdDelete } from "react-icons/md";
import { FaFileDownload } from "react-icons/fa";
import { PremissionChecker } from "../../../services/PremissionChecker";

const BranchResources = () => {
  const user = useSelector((state) => state.user.user);
  const authtoken = localStorage.getItem("userToken");

  const [loading, setloading] = useState(false);
  const [isallfields, setisallfields] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [editResourcesLoading, setEditResourcesLoading] = useState(false);
  const alert = useAlert();

  const [branchResourcesData, setBranchResourcesData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedResources, setSelectedResources] = useState([]);

  const [addModal, setAddModal] = useState(false);
  const [title, setTtile] = useState("");
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState([]);
  const [expiryDate, setExpiryDate] = useState("");

  const [editResourcesModal, setEditResourcesModal] = useState(false);
  const [editResourcesID, setEditResourcesID] = useState([]);
  const [editTitle, setEditTitle] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [editFiles, setEditFiles] = useState([]);
  const [editExpiryDate, setEditExpiryDate] = useState("");

  const [deleteResourcesModal, setDeleteResourcesModal] = useState(false);
  const [deleteableResources, setDeleteableResources] = useState("");
  const [deleteloading, setdeleteloading] = useState(false);
  const [branchUrl, setBranchUrl] = useState("");

  useEffect(() => {
    getSettingData();
  }, []);

  const getBranchResources = async () => {
    setloading(true);
    if (branchUrl == "") {
      return alert.error("Head Office Url is Required");
    }
    const credentials = btoa(`${"dass_admin"}:${"ht959J=@G#K1-14"}`);
    await fetch(`${branchUrl}/branch-resource`, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          if (data.error) {
            setloading(false);
            return alert.error(data.error);
          } else if (data.metadata.success) {
            setBranchResourcesData(data.payload);
            setloading(false);
          } else if (!data.metadata.success) {
            alert.error(data.metadata.message);
            setloading(false);
          } else {
            alert.show("Something Went Wrong");
            setloading(false);
          }
        });
      })
      .catch((err) => {
        alert.error(err);
        setloading(false);
      });
  };

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setBranchUrl(data.payload.settings.head_office_url);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Handle Add, Update and Delete Branch Resources
  const addResourcesHandler = async () => {
    if (title == "" || expiryDate == "") {
      setisallfields(true);
      return;
    }
    setAddLoading(true);
    setisallfields(false);

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("expiry_date", expiryDate);
    files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    await fetch(`${baseUrl}/branch-resource`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "testing");
        if (data.metadata) {
          setAddLoading(false);
          if (data.metadata.success) {
            alert.success("Branch Resources Successfully Added");
            setTtile("");
            setDescription("");
            setExpiryDate("");
            setFiles([]);
            getBranchResources();
            setAddModal(false);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg);
            });
            setAddModal(false);
          }
        } else {
          alert.show("Something Went Wrong");
          setAddModal(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setAddLoading(false);
      });
  };

  const getEditResources = async (id) => {
    await fetch(`${baseUrl}/branch-resource/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          setEditTitle(data.payload?.title);
          setEditDescription(data.payload?.description);
          setEditExpiryDate(data.payload?.expiry_date);
        });
      })
      .catch((err) => {
        alert.error(err);
        setloading(false);
      });
  };

  const updateResourcesHandler = async () => {
    if (editTitle == "" || editExpiryDate == "") {
      setisallfields(true);
      return;
    }
    setEditResourcesLoading(true);
    setisallfields(false);

    const formData = new FormData();
    formData.append("title", editTitle);
    formData.append("description", editDescription);
    formData.append("expiry_date", editExpiryDate);
    files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    await fetch(`${baseUrl}/branch-resource/${editResourcesID}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: formData,
    })
      .then((res) =>
        res.json().then((data) => {
          if (data.metadata.success) {
            setEditResourcesLoading(false);
            setEditResourcesModal(false);
            alert.success("Branch Resources successfully Updated");
            setFiles([]);
            getBranchResources();
          } else if (!data.metadata.success) {
            alert.error(data.metadata.message);
            setEditResourcesLoading(false);
            setEditResourcesModal(false);
          } else {
            alert.show("Something Went Wrong");
            setEditResourcesLoading(false);
            setEditResourcesModal(false);
          }
        })
      )
      .catch((err) => {
        setEditResourcesLoading(false);
        setEditResourcesModal(false);
        console.log(err);
      });
  };

  const deleteResourcesHandle = async () => {
    setdeleteloading(true);

    await fetch(`${baseUrl}/branch-resource/${deleteableResources}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            alert.success(data.metadata.message);
            setBranchResourcesData([]);
            getBranchResources();
            setdeleteloading(false);
            setDeleteResourcesModal(false);
          } else if (!data.metadata.success) {
            alert.error(data.metadata.message);
            setdeleteloading(false);
            setDeleteResourcesModal(false);
          }
        } else {
          alert.show("Something Went Wrong");
          setdeleteloading(false);
          setDeleteResourcesModal(false);
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("err", err);
        setdeleteloading(false);
        setDeleteResourcesModal(false);
      });
  };

  const showViewModal = (resources) => {
    setSelectedResources(resources);
    setShowModal(true);
  };

  // Handle File Upload
  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const removeImage = (fileToRemove) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
  };

  // Variables & Function For Pagination
  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(branchResourcesData.length / PER_PAGE);
  const _DATA = usePagination(branchResourcesData, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <div>
      <Paper
        style={{ marginTop: "15px", marginBottom: "15px", padding: "15px" }}
      >
        <div className="d-flex mt-5">
          <button
            className="btn btn-primary mx-2"
            onClick={() => getBranchResources()}
          >
            Get Branch Resources
          </button>
          {branchUrl == baseUrl &&
          PremissionChecker(user, "branch.add_branch_resources") ? (
            <button
              className="btn btn-primary mx-2"
              onClick={() => setAddModal(true)}
            >
              Add Branch Resources
            </button>
          ) : null}
        </div>

        <div className="mt-5">
          <Table responsive>
            <thead>
              <tr>
                <th className="text-center">Sr.No</th>
                <th className="text-center">Title</th>
                <th className="text-center">Description</th>
                <th className="text-center">Files</th>
                <th className="text-center">Expiry Date</th>
                <th className="text-center">View</th>
                {branchUrl == baseUrl &&
                PremissionChecker(user, "branch.update_branch_resources") ? (
                  <th className="text-center">Edit</th>
                ) : null}
                {branchUrl == baseUrl &&
                PremissionChecker(user, "branch.delete_branch_resources") ? (
                  <th className="text-center">Delete</th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {_DATA.currentData().map((ResourcesData, index) => (
                <tr key={index}>
                  <td className="text-center">{ResourcesData?.id}</td>
                  <td className="text-center">
                    {ResourcesData?.title.length > 35
                      ? ResourcesData?.title.substring(0, 35) + ".."
                      : ResourcesData?.title}
                  </td>
                  <td className="text-center">
                    {ResourcesData?.description.length > 50
                      ? ResourcesData?.description.substring(0, 50) + ".."
                      : ResourcesData?.description}
                  </td>

                  <td className="text-center">
                    {ResourcesData?.files?.map((file, fileIndex) => (
                      <a
                        key={fileIndex}
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        href={`${branchUrl.replace(
                          "/api",
                          ""
                        )}/storage/branch/notifications/${file.file}`}
                        download={file.file}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="svg-icon svg-icon-md svg-icon-success">
                          <FaFileDownload />
                        </span>
                      </a>
                    ))}
                  </td>

                  <td className="text-center">{ResourcesData?.expiry_date}</td>
                  <td className="text-center">
                    <a
                      style={{}}
                      className="btn btn-icon btn-light btn-hover-primary btn-sm "
                      onClick={() => showViewModal(ResourcesData)}
                    >
                      <span className="svg-icon svg-icon-md svg-icon-danger">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Devices/Display2.svg"
                          )}
                        />
                      </span>
                    </a>
                  </td>
                  {branchUrl == baseUrl &&
                  PremissionChecker(user, "branch.update_branch_resources") ? (
                    <td className="text-center">
                      <a
                        className={
                          "btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        }
                        onClick={() => {
                          getEditResources(ResourcesData?.id);
                          setEditResourcesID(ResourcesData?.id);
                          setEditResourcesModal(true);
                        }}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary ">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Write.svg"
                            )}
                          />
                        </span>
                      </a>
                    </td>
                  ) : null}

                  {branchUrl == baseUrl &&
                  PremissionChecker(user, "branch.delete_branch_resources") ? (
                    <td className="text-center">
                      <a
                        className={
                          "btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        }
                        onClick={() => {
                          setDeleteableResources(ResourcesData?.id);
                          setDeleteResourcesModal(true);
                        }}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-danger">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Delete-user.svg"
                            )}
                          />
                        </span>
                      </a>
                    </td>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </Table>

          <div className="row justify-content-center">
            {loading && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </div>

          <Pagination
            color="primary"
            count={count}
            size="large"
            page={page}
            variant="text"
            shape="round"
            onChange={handleChange}
          />
        </div>

        {/* ------------------------Branch Resources Start----------------------- */}

        {/* Add Branch Resources */}
        <Modal show={addModal} size="lg">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Add Branch Resources
            </Modal.Title>
          </Modal.Header>

          {addLoading ? (
            <div style={{ padding: "20px" }}>
              {" "}
              <b style={{ color: "green" }}>
                {" "}
                Branch Resources Adding Please Wait...{" "}
                <span className="spinner-borderd"></span>
              </b>
            </div>
          ) : (
            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <label>
                    <b>Enter Branch Resources Title</b>
                    <span style={{ color: "red", fontSize: 12 }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={title}
                    onChange={(e) => setTtile(e.target.value)}
                    name="resources-title"
                    placeholder="Enter Branch Resources Title"
                  />
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-12">
                  <label>
                    <b>
                      Enter Branch Resources Description{" "}
                      <span style={{ color: "red", fontSize: 12 }}>*</span>
                    </b>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    name="resources-description"
                    placeholder="Enter Branch Resources Description"
                  />
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-6">
                  <label>
                    <b>Add Files</b>
                    <span style={{ color: "red", fontSize: 12 }}>*</span>
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={handleFileChange}
                    name="files"
                    multiple
                  />
                  <div>
                    <ul>
                      {files.map((file, index) => (
                        <li key={index}>
                          {file.name}
                          <span
                            className="svg-icon svg-icon-md cursor-pointer ml-1"
                            onClick={() => removeImage(file)}
                          >
                            <MdDelete color="#F64E60" />
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="col-md-6">
                  <label>
                    <b>
                      Select Expiry Date{" "}
                      <span style={{ color: "red", fontSize: 12 }}>*</span>
                    </b>
                  </label>
                  <input
                    value={expiryDate}
                    className="form-control"
                    onChange={(e) => setExpiryDate(e.target.value)}
                    type="date"
                  />
                </div>
              </div>

              <br />
              <br />
              {isallfields ? (
                <>
                  <Alert
                    variant="danger"
                    onClose={() => setisallfields(false)}
                    dismissible
                  >
                    <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                    <p>Please fill or select all fields</p>
                  </Alert>
                </>
              ) : null}
            </Modal.Body>
          )}

          <Modal.Footer>
            <button
              className="btn btn-primary"
              onClick={addResourcesHandler}
              disabled={addLoading ? true : false}
            >
              Add Branch Resources
            </button>
            <button
              className="btn btn-danger btn_width"
              onClick={() => {
                setAddModal(false);
                setisallfields(false);
              }}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        {/* Update Branch Resources */}
        <Modal show={editResourcesModal} size="lg">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Update Branch Resources
            </Modal.Title>
          </Modal.Header>

          {editResourcesLoading ? (
            <div style={{ padding: "20px" }}>
              {" "}
              <b style={{ color: "green" }}>
                {" "}
                Resources Updating Please Wait...{" "}
                <span className="spinner-borderd"></span>
              </b>
            </div>
          ) : (
            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <label>
                    <b>Enter Branch Resources Title</b>
                    <span style={{ color: "red", fontSize: 12 }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={editTitle}
                    onChange={(e) => setEditTitle(e.target.value)}
                    name="resources-title"
                    placeholder="Enter Branch Resources Title"
                  />
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-12">
                  <label>
                    <b>
                      Enter Branch Resources Description{" "}
                      <span style={{ color: "red", fontSize: 12 }}>*</span>
                    </b>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={editDescription}
                    onChange={(e) => setEditDescription(e.target.value)}
                    name="resources-description"
                    placeholder="Enter Branch Resources Description"
                  />
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-6">
                  <label>
                    <b>Add Files</b>
                    <span style={{ color: "red", fontSize: 12 }}>*</span>
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={handleFileChange}
                    name="files"
                    multiple
                  />
                  <div>
                    <ul>
                      {files.map((file, index) => (
                        <li key={index}>
                          {file.name}
                          <span
                            className="svg-icon svg-icon-md cursor-pointer ml-1"
                            onClick={() => removeImage(file)}
                          >
                            <MdDelete color="#F64E60" />
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="col-md-6">
                  <label>
                    <b>
                      Select Expiry Date{" "}
                      <span style={{ color: "red", fontSize: 12 }}>*</span>
                    </b>
                  </label>
                  <input
                    value={editExpiryDate}
                    className="form-control"
                    onChange={(e) => setEditExpiryDate(e.target.value)}
                    type="date"
                  />
                </div>
              </div>

              <br />
              <br />
              {isallfields ? (
                <>
                  <Alert
                    variant="danger"
                    onClose={() => setisallfields(false)}
                    dismissible
                  >
                    <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                    <p>Please fill or select all fields</p>
                  </Alert>
                </>
              ) : null}
            </Modal.Body>
          )}

          <Modal.Footer>
            <button
              className="btn btn-primary"
              onClick={updateResourcesHandler}
              disabled={editResourcesLoading ? true : false}
            >
              Update Branch Resources
            </button>
            <button
              className="btn btn-danger btn_width"
              onClick={() => {
                setEditResourcesModal(false);
                setisallfields(false);
              }}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        {/* Delete Branch Resources */}
        <Modal show={deleteResourcesModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Are you sure want to delete this branch resources?
            </Modal.Title>
          </Modal.Header>
          {deleteloading ? (
            <div style={{ padding: "20px" }}>
              {" "}
              <b style={{ color: "green" }}>
                {" "}
                Branch Resource Deleting Please Wait{" "}
                <span className="spinner-borderd"></span>
              </b>
            </div>
          ) : (
            <Modal.Footer>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  onClick={deleteResourcesHandle}
                  className="btn button_blue"
                >
                  Delete
                </button>
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => setDeleteResourcesModal(false)}
                  className="btn btn-danger btn_width"
                >
                  Cancel
                </button>
              </div>
            </Modal.Footer>
          )}
        </Modal>

        {/* Show Branch Resources */}
        <Modal onHide={() => setShowModal(false)} size="lg" show={showModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              View Branch Resources
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="p-3">
              <div className="d-flex justify-content-end">
                <h5>
                  <span className="text-danger"> Expiry Date:- </span>{" "}
                  {selectedResources.expiry_date}
                </h5>
              </div>

              <h5 className="mt-4">
                <span className="text-primary"> Title:- </span>{" "}
                {selectedResources.title}
              </h5>
              <div className="mt-4">
                <h5>
                  <span className="text-primary"> Description:- </span>{" "}
                </h5>
                <p className="font-size-h6 text-justify">
                  {selectedResources.description}
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-danger btn_width"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
        {/* ------------------------Branch Resources End----------------------- */}
      </Paper>
    </div>
  );
};

export default BranchResources;
