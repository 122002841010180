import React, { useEffect, useState, useRef } from "react";
import { Table, Spinner, Button, Modal } from "react-bootstrap";
import "./styles.css";
import { useAlert } from "react-alert";
import { baseUrl } from "../../../services/config";
import Paper from "@material-ui/core/Paper";
import "../../../components/PrintChallanModal/printChallan.style.css";
import "./printstyle.css";
import MultiStudentFeeChallan from "../MultiStudentFeeChallan/MultiStudentFeeChallan.js";
import ReactToPrint from "react-to-print";
import { upperCase } from "lodash";
import { PremissionChecker } from "../../../services/PremissionChecker.js";
import { useSelector } from "react-redux";
import Barcode from "react-barcode";

const icon =
  "https://darearqam.com/wp-content/uploads/2020/03/daraarqam-logo.jpg";
function ReceivePartialFee({ orientation = "portrait" }) {
  const banksloaded = useRef(false);
  const currentDate = new Date().toISOString().slice(0, 10);
  const authtoken = localStorage.getItem("userToken");
  const user = useSelector((state) => state.user.user);

  const [allsubaccounts, setallsubaccounts] = useState([]);
  const [selectedbankcateogry, setselectedbankcateogry] = useState(1);
  const [selectedbank, setselectedbank] = useState(1);
  const [allbanks, setallbanks] = useState([]);
  const [receivingdate, setreceivingdate] = useState();
  const [totalfee, setTotalfee] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [remarksValue, setRemarksValue] = useState("");
  const [searchType, setSearchtype] = useState("adm");
  const [loading, setloading] = useState(false);
  const [studentchallans, setstudentchallans] = useState([]);
  const [selectedChallans, setSelectedChallans] = useState([]);
  const [mainCheckBoxChecked, setmaincheckboxChecked] = useState(false);
  const [searchBtn, setSearchBtn] = useState(false);
  const [receiveBtn, setReceiveBtn] = useState(false);
  const alert = useAlert();
  const searchClicked = useRef(false);
  const searchInputRef = useRef(null);
  const [challanDetails, setChallanDetails] = useState([]);
  const [selectedChallanDetails, setSelectedChallanDetails] = useState([]);
  const [feeChallanDetailAmount, setFeeChallanDetailAmount] = useState();
  const [receivingAmount, setReceivingAmount] = useState(0);
  const [printmodal, setprintmodal] = useState(false);
  const [selectedStudentID, setSelectedStudentID] = useState();
  const [selectedStudent, setSelectedStudent] = useState();
  const [selectedStudentForPrint, setSelectedStudentForPrint] = useState();
  const [
    selectedStudentForAllPrint,
    setSelectedStudentForAllPrint,
  ] = useState();
  const [isVisible, setIsVisible] = useState(false);

  const [editmodal, seteditmodal] = useState(false);
  const [detailsBtn, setDetailsBtn] = useState(true);
  const [pastChallanModal, setpastChallanModal] = useState(false);
  const [pastChallans, setpastChallans] = useState([]);
  const [studentDetails, setStudentDetails] = useState([]);

  const PRINTCOUNT = [1, 2, 3];
  const componentRef = useRef(null);
  const componentRefAll = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  const [printloading, setprintloading] = React.useState(false);
  const [allprintloading, setallprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  const [blankPage, setBlankPage] = useState(false);
  const [blankPageAll, setBlankPageAll] = useState(false);
  const pageStyle = `
  @page {
    size:letter landscape !important;
  }
  `;
  useEffect(() => {
    // getAllCampus();
    if (!banksloaded.current) {
      banksloaded.current = true;
      setreceivingdate(currentDate);
      getAllBanks();
      getSettingData();
    }

    // selectedChallansArray = [...selectedChallans];
    if (searchClicked.current) {
      setAllCheckboxes(true);
      searchClicked.current = false;
    } else {
      checkAllCheckBoxes();
    }
  }, [[], selectedChallans]);

  const testcheck = () => {
    console.log(selectedChallans);
    console.log(selectedStudentID);
    // console.log(selectedChallanDetails);
    // console.log(receivingAmount);
  };

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleMainCheckboxClick = () => {
    if (mainCheckBoxChecked) setAllCheckboxes(false);
    else setAllCheckboxes(true);
  };

  const setAllCheckboxes = (value) => {
    setmaincheckboxChecked(value);
    if (value) {
      setSelectedChallans([]);
      setReceivingAmount(0);
    }

    studentchallans.map((item, i) => {
      item.checked = value;
      if (!value) {
        RemoveSelectedChallan(item);
        handleSubtractAmount(item);
      } else {
        AddSelectedChallan(item);
        handleAddAmount(item);
      }
    });
  };

  const checkAllCheckBoxes = () => {
    if (studentchallans.length == selectedChallans.length) {
      setmaincheckboxChecked(true);
    } else if (mainCheckBoxChecked) {
      setmaincheckboxChecked(false);
    }
  };

  const handleChallansCheckBoxClick = (e, index) => {
    studentchallans.map((item, i) => {
      if (item.id == index) {
        item.checked = !item.checked;

        if (item.checked) {
          AddSelectedChallan(item);
          handleAddAmount(item);
        } else {
          RemoveSelectedChallan(item);
          handleSubtractAmount(item);
        }
      }
    });
  };

  const handleChallansDetailsCheckBoxClick = (e, index) => {
    challanDetails.map((item, i) => {
      if (item.id == index) {
        item.checked = !item.checked;

        if (item.checked) {
          AddSelectedChallanDetail(item);
        } else {
          RemoveSelectedChallanDetail(item);
        }
      }
    });
  };

  const AddSelectedChallan = (item) => {
    setSelectedChallans((prevSelectedChallans) => [
      ...prevSelectedChallans,
      item.id,
    ]);
  };

  const RemoveSelectedChallan = (item) => {
    setSelectedChallans((prevSelectedChallans) =>
      prevSelectedChallans.filter((id) => id !== item.id)
    );
  };

  const AddSelectedChallanDetail = (item) => {
    setSelectedChallanDetails((prevSelectedChallansDetails) => [
      ...prevSelectedChallansDetails,
      item.id,
    ]);
  };

  const RemoveSelectedChallanDetail = (item) => {
    setSelectedChallanDetails((prevSelectedChallansDetails) =>
      prevSelectedChallansDetails.filter((id) => id !== item.id)
    );
  };

  const handleAddAmount = (item) => {
    setReceivingAmount((prevAmount) => prevAmount + parseInt(item.payable));
  };

  const handleSubtractAmount = (item) => {
    // Subtracting item.amount from receivingAmount
    setReceivingAmount((prevAmount) => prevAmount - parseInt(item.payable));
  };

  const getMonthName = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const d = new Date(date);

    return `${monthNames[d.getMonth()]}-${d.getFullYear()}`;
  };

  const getAllBanks = () => {
    fetch(`${baseUrl}/bank_account_category`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        //  setinitialloading(false)
        console.log(data, "fetch data ");
        setallbanks(data.payload);
        if (selectedbankcateogry != "") {
          getAllSubAccounts(data.payload, 1);
          searchInputRef.current.focus();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllSubAccounts = (data, selectedBank) => {
    let tempBanks = data.filter((item) => item.id == selectedBank);

    if (tempBanks.length != 0) {
      setallsubaccounts([...tempBanks[0].bank_accounts]);
    } else {
      setallsubaccounts([]);
      setselectedbankcateogry(0);
    }
  };

  const handleChallanDetailBtn = async (challanid) => {
    console.log(challanid);

    let data = {
      challan_id: challanid,
    };

    await fetch(`${baseUrl}/fee-challan-details`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            console.log(data);
            setChallanDetails(data.payload);
            setSelectedChallanDetails([]);
          }
        }
      })
      .catch((err) => {
        alert.error("Error Occurred");
        searchInputRef.current.focus();
        console.log(err);
      });

    seteditmodal(true);
  };

  const handleSearchInput = (e) => {
    console.log(e.target.value);
    setSearchValue(e.target.value);
  };

  const handleRemarksInput = (e) => {
    console.log(e.target.value);
    setRemarksValue(e.target.value);
  };

  const handleSearch = async () => {
    if (searchValue == "") {
      alert.show("Please Enter Required Value");
      searchInputRef.current.focus();
      return;
    }

    setTotalfee(0);
    setloading(true);
    setSearchBtn(true);

    let data = {
      search_value: searchValue,
      search_type: searchType,
      challan_status: "0",
    };

    await fetch(`${baseUrl}/search-student-challans`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setloading(false);
        setSearchBtn(false);
        setDetailsBtn(false);

        if (data.metadata) {
          if (data.metadata.success) {
            console.log(data);
            if (data.payload.fee_challans_data.length == 0) {
              setstudentchallans([]);
              setSelectedChallans([]);
              setpastChallans([]);
              setIsVisible(false);

              searchInputRef.current.focus();
              searchInputRef.current.value = "";
              setSearchValue("");

              if (data.payload.past_challans_detail.length > 0) {
                alert.show("No Unpaid Challans Found");
                setpastChallans(data.payload.past_challans_detail);
                setStudentDetails(data.payload.student[0]);
              } else {
                alert.error("Student Record Not Found");
                setDetailsBtn(true);
              }
              return;
            }

            setIsVisible(true);
            document.getElementById("receiveFeeBtn").focus();

            setStudentDetails(data.payload.student[0]);
            setSelectedStudentForAllPrint(data.payload.student);
            updateNetFee(data.payload.fee_challans_data);
            setstudentchallans([]);
            setpastChallans([]);
            setstudentchallans(data.payload.fee_challans_data);

            if (data.payload.past_challans_detail.length > 0)
              setpastChallans(data.payload.past_challans_detail);

            searchClicked.current = true;
          }
        }
      })
      .catch((err) => {
        alert.error("Error Occurred");
        console.log(err);
      });
  };

  const updateNetFee = (data) => {
    var totalfee = 0;
    data.map((challanData) => {
      totalfee =
        totalfee +
        parseFloat(challanData.payable == null ? 0 : challanData.payable);
    });

    setTotalfee(totalfee);
  };

  const handleReceivingValidations = () => {
    if (selectedChallans.length == 0) {
      alert.error("Please select some challans");
      return false;
    }
    if (selectedbankcateogry == 0) {
      alert.error("Please select bank / branch");
      return false;
    }

    if (receivingdate == "") {
      alert.error("Date Not Set");
      return false;
    }

    return true;
  };

  const handleReceiveFee = async () => {
    if (!handleReceivingValidations()) return;

    setloading(true);
    setReceiveBtn(true);

    let data = {
      challan_ids: selectedChallans,
      bank_account_id: selectedbankcateogry,
      receiving_date: receivingdate,
      remarks: remarksValue,
    };

    // console.log(data);

    await fetch(`${baseUrl}/receive-partial-fee`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setloading(false);
        setReceiveBtn(false);

        if (data.metadata) {
          if (data.metadata.success) {
            console.log(data);
            alert.removeAll();
            alert.success(data.payload);

            const updatedStudentChallans = studentchallans.filter(
              (studentChallan) =>
                !selectedChallans.some(
                  (selectedChallan) => selectedChallan === studentChallan.id
                )
            );

            if (updatedStudentChallans.length == 0) {
              setIsVisible(false);
            }
            // Update the state with the filtered array
            setstudentchallans(updatedStudentChallans);
            setSelectedChallans([]);
            setRemarksValue("");
            // searchClicked.current = true;

            searchInputRef.current.focus();
            searchInputRef.current.value = "";
            updateNetFee(updatedStudentChallans);
            setReceivingAmount(0);
          }
        }
      })
      .catch((err) => {
        alert.error("Error Occurred");
        console.log(err);
      });
  };

  const onFeeChange = (e) => {
    // const challan_detail_id = e.target.value;

    if (feeChallanDetailAmount == "") {
      alert.error("Please Enter Amount");
      return;
    }
    // setFeeChallanDetailId(e.target.value);
    const data = {
      challan_detail_id: e.target.value,
      challan_amount: feeChallanDetailAmount,
    };

    console.log(data);

    fetch(`${baseUrl}/update-challan-fee`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        alert.show(data.payload);

        // if ((data = "1")) {
        //   alert.show("Data updated Sucessfully");
        // } else {
        //   alert.show("Data not updated Sucessfully");
        // }
      })
      .catch((err) => {
        console.log(err);
      });

    handleSearch();
    seteditmodal(true);
  };

  const handleChangeAmount = (e) => {
    console.log(e.target.value);
    setFeeChallanDetailAmount(e.target.value);
  };

  const handleSubPartialFeeReceivingValidations = () => {
    if (selectedChallanDetails.length == 0) {
      alert.error("fees not selected");
      return false;
    }
    if (selectedbankcateogry == 0) {
      alert.error("Please select bank / branch");
      return false;
    }
    if (receivingdate == "") {
      alert.error("Date Not Set");
      return false;
    }

    return true;
  };

  const handleSubPartialReceiveFee = async () => {
    if (!handleSubPartialFeeReceivingValidations()) return;

    setloading(true);
    setReceiveBtn(true);

    let data = {
      challan_detail_ids: selectedChallanDetails,
      bank_account_id: selectedbankcateogry,
      receiving_date: receivingdate,
    };

    // console.log(data);

    await fetch(`${baseUrl}/receive-sub-partial-fee`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setloading(false);
        setReceiveBtn(false);

        if (data.metadata) {
          if (data.metadata.success) {
            console.log(data);
            alert.success(data.payload);
            handleSearch();
            // handleSubPartialReceiveFee();
          }
        }
      })
      .catch((err) => {
        alert.error("Error Occurred");
        console.log(err);
      });
    seteditmodal(false);
  };

  const handlePrintChallan = async () => {
    if (selectedChallans.length == 0) {
      alert.show("No Challan Selected");
      return;
    }

    let data = {
      student_id: studentDetails.id,
      challan_ids: selectedChallans,
    };

    await fetch(`${baseUrl}/get-student-challans`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setloading(false);
        setSearchBtn(false);
        if (data.metadata) {
          if (data.metadata.success) {
            if (data.payload == "") {
              alert.error("Error");
              return;
            }
            console.log(data.payload.students[0]);
            console.log(data.payload.students, "data checking");
            console.log(data.payload.students[0].fee_challans_detials);

            setSelectedStudent(data.payload.students[0]);
            setSelectedStudentForPrint(data.payload.students);
            setprintmodal(true);
          }
        }
      })
      .catch((err) => {
        alert.error("Error Occurred");
        console.log(err);
      });
  };

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    var element = document.getElementById("hettingheight");

    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleOnBeforeGetAllContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setallprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setallprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const getFeeAmount = (challans) => {
    let sum = 0;
    if (Array.isArray(challans)) {
      challans.forEach((element) => {
        sum += parseInt(element.amount);
      });
    }
    return sum;
  };

  const getIssueDate = (arr) => {
    if (Array.isArray(arr) && arr.length > 0) {
      const result = arr[arr.length - 1];
      return result;
    }
    return null; // or any default value you want to return if the array is empty or undefined
  };

  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div style={{ marginTop: "20px" }}>
          <div className="d-flex m-4 w-50 justify-content-between">
            <div>
              <input
                type="radio"
                name="radiobtn"
                id="inv"
                onClick={(e) => setSearchtype(e.target.id)}
                style={{ paddingLeft: "10px" }}
              />
              <label for="inv" style={{ paddingLeft: "5px" }}>
                Challan Number
              </label>
            </div>
            <div>
              <input
                type="radio"
                name="radiobtn"
                id="reg"
                onClick={(e) => setSearchtype(e.target.id)}
                style={{ paddingLeft: "10px" }}
              />
              <label for="reg" style={{ paddingLeft: "5px" }}>
                Registration Number
              </label>
            </div>
            <div>
              <input
                type="radio"
                name="radiobtn"
                id="adm"
                onClick={(e) => setSearchtype(e.target.id)}
                style={{ paddingLeft: "10px" }}
                defaultChecked
              />
              <label for="adm" style={{ paddingLeft: "5px" }}>
                Admission Number
              </label>
            </div>
          </div>

          <div className="row">
            <div className="d-flex form-group col-md-7">
              <input
                className="form-control"
                style={{ width: "80%" }}
                type="text"
                ref={searchInputRef}
                onChange={handleSearchInput}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    handleSearch();
                  }
                }}
                id="searchInput"
                placeholder="Enter Invoice / Registration / Admission Number"
              />

              <button
                className="btn btn-primary ml-5"
                onClick={handleSearch}
                disabled={searchBtn ? true : false}
              >
                Search
              </button>

              <button
                type="button"
                style={{ width: "300px" }}
                className="btn btn-primary ml-5 "
                onClick={() => setpastChallanModal((prev) => !prev)}
                disabled={detailsBtn ? true : false}
              >
                Student Fee Details
              </button>

              {/* <button style={{ width: "300px" }} className="btn-primary ml-5" onClick={testcheck}>
                {" "}
                Test Button
              </button> */}
            </div>
          </div>

          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner class="text-center" variant="info" animation="border" />
            </div>
          ) : null}

          <div>
            {isVisible ? (
              <>
                {/* <div className="m-3 w-75">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6>
                        <b>Student Name:- </b> {studentDetails?.name}
                      </h6>
                    </div>
                    <div>
                      <h6>
                        <b> Father Name:- </b> {studentDetails?.father_name}
                      </h6>
                    </div>
                    <div>
                      <h6>
                        <b> Admission ID:- </b> {studentDetails?.admission_id}
                      </h6>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6>
                        <b>Class:- </b>{" "}
                        {studentDetails?.student_class?.name +
                          " " +
                          studentDetails?.global_section?.name}
                      </h6>
                    </div>
                    <div>
                      <h6>
                        <b> Campus:- </b> {studentDetails?.campus?.name}
                      </h6>
                    </div>
                    <div>
                      <h6></h6>
                    </div>
                  </div>
                </div> */}

                <div className="pl-4">
                  <Table className="table table-borderless">
                    <thead>
                      <tr id="tbl">
                        <th style={{ fontSize: 15, padding: "1px" }}>
                          <span style={{ color: "#121291" }}>
                            Student Name:
                          </span>{" "}
                          {studentDetails?.name}
                        </th>
                        <th style={{ fontSize: 15, padding: "1px" }}>
                          <span style={{ color: "#121291" }}>Class:</span>{" "}
                          {studentDetails?.student_class?.name +
                            " " +
                            studentDetails?.global_section?.name}
                        </th>
                        <th style={{ fontSize: 15, padding: "1px" }}>
                          <span style={{ color: "#121291" }}>Campus: </span>{" "}
                          {studentDetails?.campus?.name}
                        </th>
                      </tr>
                      <tr id="tbl">
                        <th style={{ fontSize: 15, padding: "1px" }}>
                          <span style={{ color: "#121291" }}>Father Name:</span>{" "}
                          {studentDetails?.father_name}
                        </th>
                        <th style={{ fontSize: 15, padding: "1px" }}>
                          <span style={{ color: "#121291" }}>
                            {studentDetails?.admission_id == null
                              ? "Registeration ID:"
                              : "Admission ID:"}
                          </span>{" "}
                          {studentDetails?.admission_id == null
                            ? studentDetails?.registration_id
                            : studentDetails?.admission_id}
                        </th>
                      </tr>
                    </thead>
                  </Table>
                </div>

                <Table responsive>
                  <thead>
                    <tr className="text-center">
                      <th style={{ width: "5px" }}>
                        <input
                          onClick={(e) => {
                            handleMainCheckboxClick();
                          }}
                          checked={mainCheckBoxChecked}
                          style={{}}
                          placeholder="Checkbox"
                          type="checkbox"
                        />
                      </th>
                      <th>Challan No</th>
                      <th>Payable</th>
                      <th>Fee Month</th>
                      <th>Due Date</th>
                      <th>View Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {studentchallans.map((challandata) => (
                      <tr>
                        <td style={{ width: "5px" }}>
                          <input
                            type="checkbox"
                            value={challandata.id}
                            key={challandata.id}
                            onClick={(e) => {
                              handleChallansCheckBoxClick(e, challandata.id);
                            }}
                            checked={challandata.checked ?? false}
                            style={{ border: "10px" }}
                          ></input>
                        </td>
                        <td>&nbsp;&nbsp;{challandata.challan_no}</td>

                        <td>{challandata.payable}</td>
                        <td>{getMonthName(challandata.due_date)}</td>
                        <td>{challandata.due_date}</td>

                        <td>
                          <Button
                            // variant="primary"
                            value={challandata.id}
                            className="btn btn-outline-primary"
                            onClick={() =>
                              handleChallanDetailBtn(challandata.id)
                            }
                            id="challandetailBtn"
                          >
                            <span className="small">Details</span>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <div style={{ margin: "30px auto" }}>
                  <div className="d-flex">
                    <div className="form-group col-sm-3 flex-column">
                      <label className="form-control-label">
                        <b>
                          Select Bank
                          <span style={{ color: "red", fontSize: 12 }}>*</span>
                        </b>
                      </label>
                      <select
                        class="form-control"
                        onChange={(e) => {
                          setselectedbank(e.target.value);
                          setselectedbankcateogry(0);
                          getAllSubAccounts(allbanks, e.target.value);
                        }}
                        name="mainBank"
                        id="mainBank"
                        required
                        value={selectedbank}
                      >
                        <option>Select...</option>
                        {allbanks.map((campus) => (
                          <option value={campus.id}>{campus.title}</option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group col-sm-3 flex-column">
                      <label className="form-control-label">
                        <b>
                          Select Bank Branch
                          <span style={{ color: "red" }}>*</span>
                        </b>
                      </label>
                      <select
                        class="form-control"
                        // New OnChange
                        onChange={(e) => {
                          let value = e.target.value;
                          if (value == "Select...") {
                            value = 0;
                          }
                          setselectedbankcateogry(value);
                        }}
                        name="bankBranches"
                        id="bankBranches"
                        required
                        value={selectedbankcateogry}
                      >
                        <option>Select...</option>
                        {allsubaccounts.map((item) => (
                          <option value={item.id}>{item.bank_name}</option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group col-sm-2 flex-column">
                      <label className="form-control-label">
                        <b>
                          Received Date <span style={{ color: "red" }}>*</span>
                        </b>
                      </label>
                      <input
                        type="date"
                        max="9999-11-30"
                        defaultValue={currentDate}
                        value={receivingdate}
                        className="form-control"
                        name="receivingdate"
                        id="receivingdate"
                        onChange={(e) => {
                          setreceivingdate(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group col-sm-2 flex-column">
                      <label className="form-control-label">
                        <b>Net Fee</b>
                      </label>
                      <input
                        disabled
                        id="myInput"
                        className="form-control"
                        value={totalfee}
                      />
                    </div>
                    <div className="form-group col-sm-2 flex-column">
                      <label className="form-control-label">
                        <b>Receiving Fee</b>
                      </label>
                      <input
                        disabled
                        id="myInput"
                        className="form-control"
                        value={receivingAmount}
                      />
                    </div>
                  </div>

                  <div
                    className="d-flex"
                    style={{ justifyContent: "end", paddingRight: "10px" }}
                  >
                    <div className=" mr-4" style={{ width: "400px" }}>
                      <input
                        className="form-control"
                        type="text"
                        onChange={handleRemarksInput}
                        id="remarks"
                        placeholder="Remarks..."
                      />
                    </div>
                    <div>
                      <Button
                        className="w-70 mr-4"
                        onClick={handleReceiveFee}
                        id="receiveFeeBtn"
                        disabled={receiveBtn ? true : false}
                      >
                        Receive Fee
                      </Button>
                    </div>
                    <div>
                      <Button
                        className="w-70"
                        onClick={() => {
                          handlePrintChallan();
                        }}
                      >
                        Print Challan
                      </Button>
                    </div>
                    <div className="d-flex">
                      <ReactToPrint
                        pageStyle={pageStyle}
                        documentTitle="Challan Copy"
                        onAfterPrint={handleAfterPrint}
                        onBeforeGetContent={handleOnBeforeGetAllContent}
                        onBeforePrint={handleBeforePrint}
                        removeAfterPrint={true}
                        trigger={() => (
                          <button
                            disabled={allprintloading ? true : false}
                            className="btn btn-primary w-70 ml-4"
                          >
                            {allprintloading
                              ? "Printing..."
                              : "Print All Fee Challan"}
                          </button>
                        )}
                        content={() => componentRefAll.current}
                      />

                      <label className="checkbox checkbox-single ml-5">
                        <input
                          type="checkbox"
                          onChange={(e) => setBlankPageAll(e.target.checked)}
                        />
                        <span />
                        &nbsp;&nbsp;&nbsp;Use Blank Page For Print
                      </label>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>

          <Modal show={pastChallanModal} size="xl">
            <Modal.Header>
              <Modal.Title>Student Fee Details</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex flex-column justify-content-end">
              <div style={{ height: "400px", overflow: "scroll" }}>
                <Table>
                  <thead>
                    <tr id="tbl">
                      <th style={{ fontSize: 15 }}>
                        <span style={{ color: "#121291" }}>Student Name:</span>{" "}
                        {studentDetails?.name}
                      </th>
                      <th style={{ fontSize: 15 }}>
                        <span style={{ color: "#121291" }}>Father Name:</span>{" "}
                        {studentDetails?.father_name}
                      </th>
                      <th style={{ fontSize: 15 }}>
                        <span style={{ color: "#121291" }}>
                          {" "}
                          Admission ID:{" "}
                        </span>{" "}
                        {studentDetails?.admission_id}
                      </th>
                    </tr>
                    <tr id="tbl">
                      <th style={{ fontSize: 15 }}>
                        <span style={{ color: "#121291" }}>Class:</span>{" "}
                        {studentDetails?.student_class?.name +
                          " " +
                          studentDetails?.global_section?.name}
                      </th>
                      <th style={{ fontSize: 15 }}>
                        <span style={{ color: "#121291" }}>Campus: </span>{" "}
                        {studentDetails?.campus?.name}
                      </th>
                    </tr>
                  </thead>
                </Table>
                <Table responsive>
                  <thead>
                    <tr>
                      <th style={{ fontSize: 15 }} className="text-center">
                        Challan No
                      </th>
                      <th style={{ fontSize: 15 }} className="text-center">
                        Fee Month
                      </th>
                      <th style={{ fontSize: 15 }} className="text-center">
                        Fee Name
                      </th>
                      <th style={{ fontSize: 15 }} className="text-center">
                        Received Date
                      </th>
                      <th style={{ fontSize: 15 }} className="text-center">
                        Due Date
                      </th>
                      <th style={{ fontSize: 15 }} className="text-center">
                        Amount
                      </th>
                      <th style={{ fontSize: 15 }} className="text-center">
                        Status
                      </th>
                      <th style={{ fontSize: 15 }} className="text-center">
                        Remarks
                      </th>
                      {/* <th>Edit</th>
                    <th>Delete</th>
                    <th>Print</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {pastChallans &&
                      pastChallans.map((challan) => (
                        <tr>
                          <td style={{ fontSize: 14 }} className="text-center">
                            {challan.fee_challan?.challan_no}
                          </td>
                          <td style={{ fontSize: 14 }} className="text-center">
                            {getMonthName(challan.fee_month)}
                          </td>
                          <td style={{ fontSize: 14 }} className="text-center">
                            {challan.fee_name}
                          </td>
                          <td style={{ fontSize: 14 }} className="text-center">
                            {challan.fee_challan?.received_date}
                          </td>
                          <td style={{ fontSize: 14 }} className="text-center">
                            {challan.fee_challan?.due_date}
                          </td>
                          <td style={{ fontSize: 14 }} className="text-center">
                            {challan.amount}
                          </td>
                          <td
                            style={{
                              fontSize: 14,
                              color:
                                challan?.fee_challan?.status == 0
                                  ? "Red"
                                  : challan?.fee_challan?.status == 1
                                  ? "orange"
                                  : challan?.fee_challan?.status == 2
                                  ? "Green"
                                  : "",
                            }}
                            className="text-center"
                          >
                            {challan?.fee_challan?.status == 0
                              ? "UnPaid"
                              : "Paid"}
                          </td>
                          <td style={{ fontSize: 14 }} className="text-center">
                            {challan?.fee_challan?.remarks}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
              <div className="d-flex justify-content-end mt-5 mb-4">
                <button
                  onClick={() => {
                    setpastChallanModal(false);
                  }}
                  className="btn btn-danger me-2"
                >
                  Close
                </button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal show={editmodal} size="lg">
            <Modal.Header>
              <Modal.Title>Fee Challan Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row col-lg-12">
                <Table responsive>
                  <thead>
                    <tr id="tbl">
                      <th></th>
                      <th className="text-center">Fee ID</th>
                      <th className="text-center">Fee Name</th>
                      <th className="text-center">Fee Month</th>
                      <th className="text-center">Amount</th>
                      <th className="text-center">Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {challanDetails.map((challanDetail) => (
                      <tr>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            value={challanDetail.id}
                            key={challanDetail.id}
                            onClick={(e) => {
                              handleChallansDetailsCheckBoxClick(
                                e,
                                challanDetail.id
                              );
                            }}
                            checked={challanDetail.checked ?? false}
                          />
                        </td>
                        <td className="text-center">{challanDetail.id}</td>
                        <td className="text-center">
                          {challanDetail.fee_name}
                        </td>
                        <td className="text-center">
                          {getMonthName(challanDetail.fee_month)}
                        </td>
                        <td
                          className="text-center"
                          style={{ marginRight: "10px" }}
                        >
                          <input
                            disabled={
                              !PremissionChecker(
                                user,
                                "receive_fee.can_update_amount"
                              )
                            }
                            defaultValue={challanDetail.amount}
                            type="text"
                            maxLength={6}
                            onChange={handleChangeAmount}
                            className="form-control text-center"
                            placeholder="Amount"
                            style={{ width: "120px" }}
                          />
                        </td>

                        <td className="text-center">
                          <Button
                            disabled={
                              !PremissionChecker(
                                user,
                                "receive_fee.can_update_amount"
                              )
                            }
                            value={challanDetail.id}
                            onClick={onFeeChange}
                            className="btn btn-outline-primary"
                          >
                            Update Fee
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <div className="form-group col-sm-6 flex-column d-flex">
                  <label className="form-control-label">
                    <b>
                      Select Bank
                      <span style={{ color: "red", fontSize: 12 }}>*</span>
                    </b>
                  </label>
                  <select
                    className="form-select form-control"
                    onChange={(e) => {
                      setselectedbank(e.target.value);
                      setselectedbankcateogry(0);
                      getAllSubAccounts(allbanks, e.target.value);
                    }}
                    name="mainBank"
                    id="mainBank"
                    value={selectedbank}
                    style={{ backgroundColor: "white" }}
                    required
                  >
                    <option>Select...</option>
                    {allbanks.map((campus) => (
                      <option value={campus.id}>{campus.title}</option>
                    ))}
                  </select>
                </div>

                <div className="form-group col-sm-6 flex-column d-flex ">
                  <label className="form-control-label">
                    <b>
                      Select Bank Branch
                      <span style={{ color: "red" }}>*</span>
                    </b>
                  </label>
                  <select
                    className="form-select form-control"
                    onChange={(e) => {
                      let value = e.target.value;
                      if (value == "Select...") {
                        value = 0;
                      }
                      setselectedbankcateogry(value);
                    }}
                    name="bankBranches"
                    id="bankBranches"
                    required
                    value={selectedbankcateogry}
                  >
                    <option>Select...</option>
                    {allsubaccounts.map((item) => (
                      <option value={item.id}>{item.bank_name}</option>
                    ))}
                  </select>
                </div>

                <div className="form-group col-sm-4 flex-column d-flex">
                  <label className="form-control-label">
                    <b>
                      Received Date <span style={{ color: "red" }}>*</span>
                    </b>
                  </label>
                  <input
                    type="date"
                    max="9999-11-30"
                    defaultValue={currentDate}
                    value={receivingdate}
                    className="form-control"
                    name="receivingdate"
                    id="receivingdate"
                    onChange={(e) => {
                      setreceivingdate(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  onClick={handleSubPartialReceiveFee}
                  id="receiveFeeBtn"
                  className="mr-4"
                  disabled={receiveBtn ? true : false}
                >
                  Receive Fee
                </Button>

                <button
                  onClick={() => {
                    seteditmodal(false);
                    setSelectedChallanDetails([]);
                  }}
                  className="btn btn-danger me-2"
                >
                  Close
                </button>
              </div>
            </Modal.Body>
          </Modal>

          {printmodal ? (
            <Modal show={printmodal} size="lg">
              <div id="voucher">
                <Modal.Body>
                  <div className="main__voucher">
                    {PRINTCOUNT.map((item, indexs) => (
                      <div key={indexs} className="voucher">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <img
                            src={icon}
                            style={{ height: 100, width: 100 }}
                            alt="icon"
                          />
                          <h3 style={{ color: "#B4959D", width: "40%" }}>
                            {unitName}
                          </h3>
                        </div>
                        <div className="redline"></div>
                        <div className="bolsection">
                          <div>
                            <div>Issue Date:</div>
                            <div>Due Date :</div>
                            <div>
                              {" "}
                              {selectedStudent?.admission_id == null
                                ? "Registration Id:"
                                : "Admission No:"}
                            </div>
                            <div>Name:</div>
                            <div>Class:</div>
                          </div>
                          <div>
                            <div>
                              {
                                getIssueDate(
                                  selectedStudent?.fee_challans_detials
                                )?.fee_challan?.issue_date
                              }
                            </div>
                            <div>
                              {
                                getIssueDate(
                                  selectedStudent?.fee_challans_detials
                                )?.fee_challan?.due_date
                              }
                            </div>
                            <div>
                              {selectedStudent?.admission_id == null
                                ? selectedStudent?.registration_id
                                : selectedStudent?.admission_id}
                            </div>
                            <div>{selectedStudent?.name}</div>
                            <div>{selectedStudent?.student_class_name}</div>
                          </div>
                        </div>

                        {selectedStudent?.fee_challans_detials &&
                          selectedStudent?.fee_challans_detials.map(
                            (copy, index) => {
                              return (
                                <div>
                                  <div className="bolsection printchallanfeesection">
                                    <div style={{ fontSize: "smaller" }}>
                                      {copy?.fee_type_id == "23"
                                        ? "REMAINING BALANCE"
                                        : copy?.fee_name}
                                    </div>
                                    <div style={{ fontSize: "smaller" }}>
                                      {copy?.amount}
                                    </div>
                                    {/* <div style={{ fontSize: "smaller" }}>
                                     {copy.fee_month}
                                   </div> */}
                                  </div>
                                </div>
                              );
                            }
                          )}

                        <div className="lastsection">
                          <h5>
                            Total Amount:{" "}
                            {getFeeAmount(
                              selectedStudent?.fee_challans_detials
                            )}{" "}
                          </h5>
                        </div>

                        <div className="lastsection">
                          <Barcode
                            value={
                              selectedStudent?.admission_id == null
                                ? selectedStudent?.registration_id
                                : selectedStudent?.admission_id
                            }
                            height={50}
                            displayValue={false}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </Modal.Body>
              </div>

              <Modal.Footer>
                <label className="checkbox checkbox-single mr-5">
                  <input
                    type="checkbox"
                    onChange={(e) => setBlankPage(e.target.checked)}
                  />
                  <span />
                  &nbsp;&nbsp;&nbsp;Use Blank Page For Print
                </label>

                <ReactToPrint
                  pageStyle={pageStyle}
                  documentTitle="Challan Copy"
                  onAfterPrint={handleAfterPrint}
                  onBeforeGetContent={handleOnBeforeGetContent}
                  onBeforePrint={handleBeforePrint}
                  removeAfterPrint={true}
                  trigger={() => (
                    <button
                      disabled={printloading ? true : false}
                      className="btn btn-primary w-70"
                    >
                      {printloading ? "Printing..." : "Print Fee Challan"}
                    </button>
                  )}
                  content={() => componentRef.current}
                />
                <button
                  id="cancelprintButton"
                  onClick={() => setprintmodal(false)}
                  className="btn btn-danger btn_width"
                >
                  Cancel
                </button>
              </Modal.Footer>
            </Modal>
          ) : null}

          <div
            ref={componentRef}
            id="printSection"
            style={{ visibility: "hidden" }}
          >
            <MultiStudentFeeChallan
              allstudentList={selectedStudentForPrint}
              blankPage={blankPage}
            />
          </div>

          {/* All Fee Challans */}
          <div
            ref={componentRefAll}
            id="printSection"
            style={{ visibility: "hidden" }}
          >
            <MultiStudentFeeChallan
              allstudentList={selectedStudentForAllPrint}
              blankPage={blankPageAll}
            />
          </div>
        </div>
      </Paper>
    </>
  );
}

export default ReceivePartialFee;
