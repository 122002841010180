import React, { useEffect, useRef, useState } from "react";
import { Paper } from "@material-ui/core";
import { baseUrl } from "../../../services/config";
import { DatePicker } from "antd";
import "./mycss.css";
import { Spinner, Table } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { useAlert } from "react-alert";
import NewAdmissionExport from "./NewAdmissionExport";
import { upperCase } from "lodash";
import { PremissionChecker } from "../../../services/PremissionChecker";
import { useSelector } from "react-redux";
const { RangePicker } = DatePicker;

const NewAdmissions = () => {
  const user = useSelector((state) => state.user.user);
  const [campuses, setallcampuses] = useState(user.campuses || []);
  const [fullSession, setFullSession] = useState({});
  const [yearId, setYearId] = useState("");
  const [campusId, setCampusId] = useState("");
  const [campusName, setCampusName] = useState([]);
  const [loading, setloading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [sessionData, setSessionData] = useState([]);
  const [gender, setGender] = useState("both");
  // const [listType, setListType] = useState("session");
  // const [listType, setListType] = useState("session");
  const [listType, setListType] = useState(
    PremissionChecker(user, "system_reports.session_wise_status_list")
      ? "session"
      : "campus"
  );

  const [startDate, setStartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [studentStatus, setstudentStatus] = useState("");
  const [statusName, setStatusName] = useState([]);

  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const authtoken = localStorage.getItem("userToken");
  const alert = useAlert("");

  const [isVisible, setIsVisible] = useState(false);
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  const [campus, setCampus] = useState(false);
  // const [campus, setCampus] = useState(
  //   JSON.parse(localStorage.getItem("userDetails")).role[0] == "Campus"
  //     ? true
  //     : false
  // );

  // const [selected, setSelected] = useState(
  //   JSON.parse(localStorage.getItem("userDetails")).role[0] == "Campus"
  //     ? "campus"
  //     : "session"
  // );

  // const loginAccount = JSON.parse(localStorage.getItem("userDetails")).role[0];

  const pageStyle = `
    @page {
      margin-left: 1.5in;
      size:legal landscape !important;
  }
  `;
  const STUD_STATUS = [
    {
      id: 1,
      title: "Registered Only",
    },
    {
      id: 2,
      title: "Admission Only",
    },
    {
      id: 3,
      title: "Applied For Admission",
    },
    {
      id: 4,
      title: "Struck Off",
    },
    {
      id: 5,
      title: "Passout",
    },
    {
      id: 6,
      title: "Leaving",
    },
    {
      id: 7,
      title: "Migrate",
    },
  ];
  useEffect(() => {
    getSettingData();
    // getAllCampus();
    sessions();
  }, []);

  const sessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            console.log(session);
            setFullSession(session);
            setYearId(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "yrr q nahi show ho rahy");
        setallcampuses(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };
  const handleShow = () => {
    if (startDate == "" || endDate == "") {
      alert.error("Start Date And End Date Field Is Required");
      return;
    }
    if (studentStatus == "") {
      alert.error("Status Field Is Required");
      return;
    }

    let data = {
      start_date: startDate,
      end_date: endDate,
      campus_id: listType == "campus" ? campusId : "",
      year_id: yearId,
      gender: gender == "male" ? "Male" : gender == "female" ? "Female" : "0",
      status: studentStatus,
    };
    setloading(true);

    fetch(`${baseUrl}/new-students-report`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          setloading(false);
          if (data.metadata) {
            if (data.metadata.success) {
              setReportData(data.payload);
            }
          }
        })
      )

      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };
  const ongenderButtonClick = (e) => {
    setGender(e.target.value);
  };
  const onListTypeChange = (e) => {
    setListType(e.target.value);
  };
  const datePicker = (value, dateString) => {
    console.log("Formatted Selected Time: ", dateString);
    setStartDate(dateString[0]);
    setendDate(dateString[1]);
  };
  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  let num = 0;
  let num2 = 0;
  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-md-6 text-center">
            <label>
              <b>Select Wise</b>
            </label>
          </div>
          <div className="col-md-6 text-center">
            <label>
              <b>Select Gender</b>
            </label>
          </div>
        </div>

        <div className="row">
          {/* Select Wise */}
          <div className="col-sm-3 text-center">
            <input
              disabled={
                !PremissionChecker(
                  user,
                  "system_reports.session_wise_status_list"
                )
              }
              checked={listType == "session"}
              onChange={(e) => onListTypeChange(e)}
              type="radio"
              id="session"
              name="listType"
              value="session"
            />
            <label for="session">Session Wise</label>
          </div>

          <div className="col-sm-3 text-center">
            <input
              type="radio"
              checked={listType == "campus"}
              onChange={(e) => onListTypeChange(e)}
              id="campus"
              name="listType"
              value="campus"
            />
            <label for="campus">Campus Wise</label>
          </div>

          {/* Select Gender */}
          <div className="col-sm-2 text-center">
            <input
              checked={gender == "male"}
              onChange={(e) => ongenderButtonClick(e)}
              type="radio"
              id="Male"
              name="gender"
              value="male"
            />
            <label for="Male">Male</label>
          </div>

          <div className="col-sm-2 text-center">
            <input
              type="radio"
              checked={gender == "female"}
              onChange={(e) => ongenderButtonClick(e)}
              id="Female"
              name="gender"
              value="female"
            />
            <label for="Female">Female</label>
          </div>

          <div className="col-sm-2 text-center">
            <input
              checked={gender == "both"}
              onChange={(e) => ongenderButtonClick(e)}
              type="radio"
              id="Both"
              name="gender"
              value="both"
            />
            <label for="Both">Both</label>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-md-3">
            <label>
              <b>Select Session</b>
            </label>
            <div className="d-flex flex-column ">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                value={yearId}
                aria-label="Default select example"
                onChange={(e) => {
                  const selectedObject = sessionData.find(
                    (option) => option.id == e.target.value
                  );
                  setFullSession(selectedObject);
                  setYearId(e.target.value);
                }}
              >
                <option value="">Select Session</option>
                {sessionData &&
                  sessionData.map((item) => (
                    <option value={item.id}>{item.year}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-lg-3">
            <label>
              <b>Student Status</b>
            </label>
            <select
              class="form-select form-control"
              value={studentStatus}
              style={{ height: 40 }}
              onChange={(e) => {
                const selectedObject = STUD_STATUS.find(
                  (option) => option.id == e.target.value
                );
                setStatusName(selectedObject);
                setstudentStatus(e.target.value);
              }}
              aria-label="Default select example"
            >
              <option value="">Select Status</option>
              {STUD_STATUS.map((item) => (
                <option value={item.id}>{item.title}</option>
              ))}
            </select>
          </div>
          {listType == "campus" && (
            <div className="col-md-3">
              <label>
                <b>Select Campus</b>
              </label>
              <select
                className="form-select"
                name="campus_id"
                onChange={(e) => {
                  const selectedObject = campuses.find(
                    (option) => option.id == e.target.value
                  );
                  setCampusName(selectedObject);
                  setCampusId(e.target.value);
                }}
                style={{ height: 40 }}
                aria-label="Default select example"
              >
                <option value=""> Select Campus</option>
                {campuses.map((campus, index) => (
                  <option key={index} value={campus.id}>
                    {campus.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="col-md-3">
            <label>
              <b>Select Start and End Date</b>
            </label>
            <RangePicker style={{ height: "40px" }} onChange={datePicker} />
          </div>

          <div className="col-md-3 mt-9 d-flex">
            <button className="btn btn-primary mx-2" onClick={handleShow}>
              Show
            </button>
            {reportData.length > 0 && (
              <>
                <ReactToPrint
                  documentTitle={`New Admission Report`}
                  pageStyle={pageStyle}
                  onAfterPrint={handleAfterPrint}
                  onBeforeGetContent={handleOnBeforeGetContent}
                  onBeforePrint={handleBeforePrint}
                  removeAfterPrint={true}
                  trigger={() => (
                    <button
                      // onClick={() => console.log("dfsdfsdfd")}
                      disabled={printloading ? true : false}
                      className="btn btn-primary w-100px mx-2"
                    >
                      {printloading ? <span>Printing..</span> : "Print"}
                    </button>
                  )}
                  content={() => componentRef.current}
                />
                <NewAdmissionExport reportData={reportData} />
              </>
            )}
          </div>
        </div>

        {loading ? (
          <div
            className="mt-5"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner
              className="text-center"
              variant="info"
              animation="border"
            />
          </div>
        ) : (
          <div className=" mt-5" id="tableboot">
            <div className="row p-3">
              <img
                // className='col-md-2'
                style={{ height: 100, width: 100 }}
                src={icon}
              />

              <div className="col-md-11">
                <h1>{unitName}</h1>
                <h5>
                  LIST OF STUDENTS WITH STATUS
                  {studentStatus != ""
                    ? ` ( ${upperCase(statusName?.title)} ) `
                    : null}
                  OF SESSION {fullSession?.year}
                  {campusId != ""
                    ? ` ( ${upperCase(campusName?.name)} ) `
                    : null}
                  {startDate != "" && endDate != ""
                    ? `(FROM ${startDate} TO ${endDate})`
                    : null}
                </h5>
              </div>
            </div>
            <ColoredLine color={"#FF0000"} />

            {reportData.length > 0 && (
              <Table responsive>
                <thead>
                  <tr id="tbl">
                    <th>Sr.</th>
                    <th>Adm-Id</th>
                    <th>Student Name</th>
                    <th>Father Name</th>
                    <th>Mobile No</th>
                    <th>DOB</th>
                    <th>Gender</th>
                    <th>Address</th>
                  </tr>
                </thead>

                <tbody>
                  {reportData.map((mainitem) => (
                    <>
                      <div style={{ height: "40px", position: "relative" }}>
                        <p
                          style={{
                            color: "black",
                            width: "200px",
                            fontWeight: "bold",
                            fontSize: "15px",
                            position: "absolute",
                            left: "80px",
                          }}
                          className="text_align_start"
                        >
                          {mainitem.Campus}
                        </p>
                      </div>
                      {mainitem.classes.map((item) =>
                        item.sections.map((subitem) => (
                          <>
                            {subitem.students.length > 0 && (
                              <div
                                style={{
                                  height: "40px",
                                  display: "flex",
                                  position: "relative",
                                }}
                              >
                                <p
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                    position: "absolute",
                                    left: "70px",
                                  }}
                                  className="text-center"
                                >
                                  {" "}
                                  Class:
                                </p>
                                <p
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                    position: "absolute",
                                    left: "150px",
                                    width: "400px",
                                    display: "flex",
                                  }}
                                  className="text-center"
                                >
                                  {item.Class.name}
                                </p>
                                <p
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                    position: "absolute",
                                    left: "350px",
                                  }}
                                  className="text_align_start"
                                >
                                  Section:
                                </p>
                                <p
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                    position: "absolute",
                                    left: "450px",
                                    width: "300px",
                                  }}
                                  className="text_align_start"
                                >
                                  {subitem.Section.name}
                                </p>
                              </div>
                            )}
                            {subitem.students.length > 0 &&
                              subitem.students.map((student, i) => (
                                <tr id="tbl">
                                  <td
                                    style={{ color: "black" }}
                                    className="text_align_start"
                                  >
                                    {(num += 1)}
                                  </td>
                                  <td
                                    style={{ color: "black" }}
                                    className="text_align_start"
                                  >
                                    {student.admission_id}
                                  </td>
                                  <td
                                    style={{ color: "black" }}
                                    className="text_align_start"
                                  >
                                    {student.name}
                                  </td>
                                  <td
                                    style={{ color: "black" }}
                                    className="text_align_start"
                                  >
                                    {student.father_name}
                                  </td>
                                  <td
                                    style={{ color: "black" }}
                                    className="text_align_start"
                                  >
                                    {student.mobile_no}
                                  </td>
                                  <td
                                    style={{ color: "black" }}
                                    className="text_align_start"
                                  >
                                    {student.dob}
                                  </td>
                                  <td
                                    style={{ color: "black" }}
                                    className="text_align_start"
                                  >
                                    {student.gender}
                                  </td>
                                  <td
                                    style={{ color: "black" }}
                                    className="text_align_start"
                                  >
                                    {student.address}
                                  </td>
                                </tr>
                              ))}
                          </>
                        ))
                      )}
                    </>
                  ))}
                </tbody>
              </Table>
            )}
          </div>
        )}
        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint marginLeftTolegal"
        >
          <div id="tableboot" style={{ padding: "0px 10px" }}>
            <div className="row p-3">
              <img
                // className='col-md-2'
                style={{ height: 100, width: 100 }}
                src={icon}
              />

              <div className="col-md-10">
                <h1>{unitName}</h1>
                <h5>
                  LIST OF STUDENTS WITH STATUS
                  {studentStatus != ""
                    ? ` ( ${upperCase(statusName?.title)} ) `
                    : null}
                  OF SESSION {fullSession?.year}
                  {campusId != ""
                    ? ` ( ${upperCase(campusName?.name)} ) `
                    : null}
                  {startDate != "" && endDate != ""
                    ? `(FROM ${startDate} TO ${endDate})`
                    : null}
                </h5>
              </div>
            </div>
            <ColoredLine color={"#FF0000"} />

            {reportData.length > 0 && (
              <table style={{ width: "100%" }}>
                <thead>
                  <tr id="tbl">
                    <td style={{ color: "black", textAlign: "start" }}>
                      <b style={{ borderBottom: "1px solid black" }}>Sr.</b>
                    </td>
                    <td style={{ color: "black" }}>
                      <b style={{ borderBottom: "1px solid black" }}>Adm-Id</b>
                    </td>
                    <td style={{ textAlign: "start", color: "black" }}>
                      <b style={{ borderBottom: "1px solid black" }}>
                        Student Name
                      </b>
                    </td>
                    <td style={{ textAlign: "start", color: "black" }}>
                      <b style={{ borderBottom: "1px solid black" }}>
                        Father Name
                      </b>
                    </td>
                    <td style={{ color: "black", textAlign: "start" }}>
                      <b style={{ borderBottom: "1px solid black" }}>
                        Mobile No
                      </b>
                    </td>
                    <td style={{ color: "black" }}>
                      <b style={{ borderBottom: "1px solid black" }}>DOB</b>
                    </td>
                    <td style={{ color: "black" }}>
                      <b style={{ borderBottom: "1px solid black" }}>Gender</b>
                    </td>
                    <td style={{ color: "black" }}>
                      <b style={{ borderBottom: "1px solid black" }}>Address</b>
                    </td>
                  </tr>
                </thead>

                <tbody>
                  {reportData?.map((mainitem) => (
                    <>
                      <div style={{ height: "20px", position: "relative" }}>
                        <p
                          style={{
                            color: "black",
                            width: "200px",
                            fontWeight: "bold",
                            fontSize: "15px",
                            position: "absolute",
                            left: "80px",
                          }}
                          className="text_align_start"
                        >
                          {mainitem.Campus}
                        </p>
                      </div>
                      {mainitem.classes.map((item) =>
                        item.sections.map((subitem) => (
                          <>
                            {subitem.students.length > 0 && (
                              <div
                                className="hadhybhai"
                                style={{
                                  height: "20px",
                                  display: "flex",
                                  position: "relative",
                                }}
                              >
                                <p
                                  style={{
                                    color: "black",
                                    // fontWeight: "bold",
                                    fontSize: "18px",
                                    position: "absolute",
                                    left: "30px",
                                  }}
                                  className="text_align_start duefeesec"
                                >
                                  {" "}
                                  <b>Class:- {item.Class.name}</b>
                                </p>

                                <p
                                  style={{
                                    color: "black",
                                    // fontWeight: "bold",
                                    fontSize: "18px",
                                    position: "absolute",
                                    left: "350px",
                                  }}
                                  className="text_align_start duefeesec"
                                >
                                  <b>Section:{subitem.Section.name}</b>
                                </p>
                              </div>
                            )
                            //  <div
                            //           className="hadhybhai"

                            //     style={{
                            //       height: "20px",
                            //       display: "flex",
                            //       position: "relative",
                            //     }}
                            //   >
                            //     <p
                            //       style={{
                            //         color: "black",
                            //         fontWeight: "bold",
                            //         fontSize: "15px",
                            //         position: "absolute",
                            //         left: "70px",
                            //       }}
                            //       className="text-center"
                            //     >
                            //       {" "}
                            //       Class:
                            //     </p>
                            //     <p
                            //       style={{
                            //         color: "black",
                            //         fontWeight: "bold",
                            //         fontSize: "15px",
                            //         position: "absolute",
                            //         left: "150px",
                            //         width:"400px",
                            //         display:"flex"
                            //       }}
                            //       className="text-center"
                            //     >
                            //       {item.Class.name}
                            //     </p>
                            //     <p
                            //       style={{
                            //         color: "black",
                            //         fontWeight: "bold",
                            //         fontSize: "15px",
                            //         position: "absolute",
                            //         left: "350px",

                            //       }}
                            //       className="text_align_start"
                            //     >
                            //       Section:
                            //     </p>
                            //     <p
                            //       style={{
                            //         color: "black",
                            //         fontWeight: "bold",
                            //         fontSize: "15px",
                            //         position: "absolute",
                            //         left: "450px",
                            //         width: "300px",
                            //       }}
                            //       className="text_align_start"
                            //     >
                            //       {subitem.Section.name}
                            //     </p>
                            //   </div>
                            }
                            {subitem.students.length > 0 &&
                              subitem.students.map((student, i) => (
                                <tr
                                  id="tbl managingtrHeight"
                                  style={{ borderBottom: "1px solid black" }}
                                >
                                  <td
                                    style={{ color: "black", minWidth: "50px" }}
                                    className="text_align_start checklistlinehighter"
                                  >
                                    {(num2 += 1)}
                                  </td>
                                  <td
                                    style={{ color: "black", width: "70px" }}
                                    className="text_align_start checklistlinehighter"
                                  >
                                    {student.admission_id}
                                  </td>
                                  <td
                                    style={{ color: "black", width: "250px" }}
                                    className="text_align_start checklistlinehighter"
                                  >
                                    {student.name.length > 25
                                      ? student.name.slice(0, 25)
                                      : student.name}
                                  </td>
                                  <td
                                    style={{ color: "black", width: "250px" }}
                                    className="text_align_start checklistlinehighter"
                                  >
                                    {student.father_name.length > 25
                                      ? student.father_name.slice(0, 25)
                                      : student.father_name}
                                  </td>
                                  <td
                                    style={{
                                      color: "black",
                                      paddingRight: "10px",
                                    }}
                                    className="text_align_start checklistlinehighter"
                                  >
                                    {student.mobile_no}
                                  </td>
                                  <td
                                    style={{ color: "black" }}
                                    className="text_align_start checklistlinehighter checkDobheight"
                                  >
                                    {student.dob}
                                  </td>
                                  <td
                                    style={{ color: "black" }}
                                    className="text_align_start checklistlinehighter"
                                  >
                                    {student.gender}
                                  </td>
                                  <td
                                    style={{ color: "black" }}
                                    className="text_align_start checkAddress"
                                  >
                                    {student.address}
                                  </td>
                                </tr>
                              ))}
                          </>
                        ))
                      )}
                    </>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </Paper>
    </>
  );
};

export default NewAdmissions;

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 5,
    }}
  />
);
