import React, { useEffect } from "react";
import { Paper } from "@material-ui/core";
import { baseUrl } from "../../../../services/config";
import { Spinner, Table } from "react-bootstrap";
import { useState, useRef } from "react";
import ReactToPrint from "react-to-print";
import { useSelector } from "react-redux";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { DatePicker } from "antd";
import { upperCase } from "lodash";
import { useAlert } from "react-alert";

const ProvidentFundReport = () => {
  const { RangePicker } = DatePicker;
  const [loading, setLoading] = useState(false);
  //for print
  const [printloading, setprintloading] = React.useState(false);
  const user = useSelector((state) => state.user.user);
  const [campusData, setCampusData] = useState(user.campuses || []);
  const [campusName, setCampusName] = useState("All Campuses");
  const [text, setText] = React.useState("old boring text");
  const [campusId, setCampusId] = useState([]);
  const [months, setMonths] = useState([]);
  const componentRef = useRef(null);
  const tableRef = useRef(null);
  let authtoken = localStorage.getItem("userToken");
  const [reportData, setreportData] = useState([]);
  const onBeforeGetContentResolve = React.useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const pageStyle = `
  @page {
    size: landscape !important;
}
`;
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);
  const alert = useAlert();
  const ColoredLine = ({ color }) => (
    <hr
      style={{
        color: color,
        backgroundColor: color,
        height: 5,
      }}
    />
  );

  useEffect(() => {
    getSettingData();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProvidentFund = async () => {
    setLoading(true);
    setIsVisible(false);
    let campus = campusData.find((c) => c.id == campusId);
    setCampusName(campus ? campus.name : "All Campuses");

    let data = {
      campus_ids: campusId == "" ? [] : [campusId],
      months: months,
    };
    await fetch(`${baseUrl}/provident-fund-report`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            setLoading(false);
            setIsVisible(true);
            setreportData(data.payload);
          } else if (!data.metadata.success) {
            alert.error(data.metadata.message);
            setreportData([]);
            setLoading(false);
            setIsVisible(false);
            return;
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const handleChanger = (day, date) => {
    let startDate = date[0] + "-01";
    let endDate = date[1] + "-01";
    var start = startDate.split("-");
    var end = endDate.split("-");
    var startYear = parseInt(start[0]);
    var endYear = parseInt(end[0]);
    var dates = [];

    for (var i = startYear; i <= endYear; i++) {
      var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        var month = j + 1;
        var displayMonth = month < 10 ? "0" + month : month;
        dates.push([i, displayMonth, "01"].join("-"));
      }
    }
    setMonths(dates);
    //  console.log(dates)
  };

  const getMonthName = (date) => {
    const monthNames = [
      "January", // index 0
      "February", // index 1
      "March", // index 2
      "April", // index 3
      "May", // index 4
      "June", // index 5
      "July", // index 6
      "August", // index 7
      "September", // index 8
      "October", // index 9
      "November", // index 10
      "December", // index 11
    ];
    const d = new Date(date);

    return `${monthNames[d.getMonth()]}, ${d.getFullYear()}`;
  };

  const todayDate = () => {
    let d = new Date();
    var curr_moth = ("0" + (d.getMonth() + 1)).slice(-2);
    var curr_date = ("0" + d.getDate()).slice(-2);
    let newdate = `${d.getFullYear()}-${curr_moth}-${curr_date}`;
    return newdate;
  };

  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-md-3">
            <label>
              <b> Select Campus</b>
            </label>
            <div className="d-flex flex-column">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                aria-label="Default select example"
                onChange={(e) => setCampusId(e.target.value)}
              >
                <option value=""> All Campuses</option>
                {campusData &&
                  campusData.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </select>
            </div>
          </div>

          <div className="col-md-3">
            <label>
              <b> Select Months</b>
            </label>
            <div className="d-flex flex-column">
              <RangePicker
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                style={{ height: "40px" }}
                picker="month"
                onChange={(day, date) => handleChanger(day, date)}
              />
            </div>
          </div>

          <div className="col-md-4 mt-9">
            <button
              className="btn btn-primary"
              onClick={() => getProvidentFund()}
            >
              Show
            </button>
            {reportData?.length > 0 ? (
              <ReactToPrint
                documentTitle={`Provident Fund Report`}
                pageStyle={pageStyle}
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                trigger={() => (
                  <button
                    // onClick={() => console.log("dfsdfsdfd")}
                    disabled={printloading ? true : false}
                    style={{ marginLeft: "10px" }}
                    className="btn btn-primary mx-2"
                  >
                    {printloading ? <span>Printing..</span> : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />
            ) : null}

            {reportData?.length > 0 ? (
              <DownloadTableExcel
                filename="Provident Fund Report"
                sheet="Provident Fund Report"
                currentTableRef={tableRef.current}
              >
                <button className="btn btn-primary mx-2"> Export </button>
              </DownloadTableExcel>
            ) : null}
          </div>
        </div>

        {loading ? (
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner
              className="text-center"
              variant="info"
              animation="border"
            />
          </div>
        ) : isVisible ? (
          <React.Fragment>
            <div id="tableboot">
              <div className="row p-3 mt-12">
                <img style={{ height: 110, width: 120 }} src={icon} />

                <div className="col-md-8">
                  <h1>{unitName}</h1>
                  <h3>
                    Provident Fund Report
                    {campusName != "" ? "( " + campusName + " )" : null}
                  </h3>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "90%",
                      marginTop: "10px",
                    }}
                  >
                    <h5>Printing Date :- {todayDate()}</h5>
                  </div>
                </div>
              </div>

              <ColoredLine color={"#FF0000"} />
              <Table className="mt-5" responsive>
                <thead>
                  <tr className="text-center">
                    <th>Code</th>
                    <th>Name</th>
                    <th>Scale</th>
                    <th>Designation</th>
                    <th>Account No</th>
                    <th>Joining Date</th>
                    <th>GPF Contribution</th>
                    <th>GPF Deduction</th>
                    <th>GPF Total</th>
                  </tr>
                </thead>
                {reportData.length > 0
                  ? reportData.map(
                      (item, index) =>
                        item.campus_data.length > 0 &&
                        item.campus_data.map((subitem, subindex) => (
                          <React.Fragment key={index[subindex]}>
                            <tbody>
                              <tr>
                                <td
                                  colSpan={5}
                                  className="text-center text-primary"
                                  style={{
                                    fontSize: "1.3rem",
                                    fontWeight: "500",
                                    width: "max-content",
                                    padding: "1px",
                                    borderTop: "2px solid black",
                                    borderBottom: "2px solid black",
                                  }}
                                >
                                  {item?.campus}
                                </td>
                                <td
                                  colSpan={4}
                                  className="text-center text-primary"
                                  style={{
                                    fontSize: "1.3rem",
                                    fontWeight: "500",
                                    width: "max-content",
                                    padding: "1px",
                                    borderTop: "2px solid black",
                                    borderBottom: "2px solid black",
                                  }}
                                >
                                  {getMonthName(subitem?.month)}
                                </td>
                              </tr>
                            </tbody>
                            <tbody>
                              {subitem.month_data.length > 0
                                ? subitem.month_data.map(
                                    (subsubitem, sub_sub_index) => (
                                      <tr key={sub_sub_index}>
                                        <td>
                                          {subsubitem?.employee?.emp_code}
                                        </td>
                                        <td>
                                          {subsubitem?.employee?.full_name}
                                        </td>
                                        <td>
                                          {
                                            subsubitem?.employee?.pay_scale
                                              ?.payscale
                                          }
                                        </td>
                                        <td>
                                          {
                                            subsubitem?.employee?.designation
                                              ?.name
                                          }
                                        </td>
                                        <td>
                                          {subsubitem?.employee?.account_no !=
                                          null
                                            ? subsubitem?.employee?.account_no
                                            : "----"}
                                        </td>
                                        <td>
                                          {subsubitem?.employee?.joining_date}
                                        </td>
                                        <td>{subsubitem?.gpf_contribution}</td>
                                        <td>{subsubitem?.gpf_deduction}</td>
                                        <td>{subsubitem?.gpf_total}</td>
                                      </tr>
                                    )
                                  )
                                : null}
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td
                                  className="font-size-h6 font-weight-bolder text-black text-center text-primary"
                                  style={{
                                    borderBottom: "2px solid black",
                                    borderTop: "2px solid black",
                                    borderLeft: "2px solid black",
                                  }}
                                >
                                  Grand Total:-
                                </td>
                                <td
                                  className="font-size-h6 font-weight-bolder text-black text-center"
                                  style={{
                                    borderBottom: "2px solid black",
                                    borderTop: "2px solid black",
                                  }}
                                >
                                  {Intl.NumberFormat("en-Us").format(
                                    subitem.month_data.reduce(
                                      (a, c) =>
                                        a + parseInt(c.gpf_contribution),
                                      0
                                    )
                                  )}
                                </td>
                                <td
                                  className="font-size-h6 font-weight-bolder text-black text-center"
                                  style={{
                                    borderBottom: "2px solid black",
                                    borderTop: "2px solid black",
                                  }}
                                >
                                  {Intl.NumberFormat("en-Us").format(
                                    subitem.month_data.reduce(
                                      (a, c) => a + parseInt(c.gpf_deduction),
                                      0
                                    )
                                  )}
                                </td>
                                <td
                                  className="font-size-h6 font-weight-bolder text-black text-center"
                                  style={{
                                    borderBottom: "2px solid black",
                                    borderTop: "2px solid black",
                                    borderRight: "2px solid black",
                                  }}
                                >
                                  {Intl.NumberFormat("en-Us").format(
                                    subitem.month_data.reduce(
                                      (a, c) => a + parseInt(c.gpf_total),
                                      0
                                    )
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </React.Fragment>
                        ))
                    )
                  : null}
              </Table>
            </div>
          </React.Fragment>
        ) : null}

        {/* ///for print /////// */}

        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          <div className="d-flex">
            <img style={{ height: 110, width: 120 }} src={icon} />

            <div className="col-md-8 pl-5">
              <h1 style={{ color: "black" }}>{unitName}</h1>
              <h3 style={{ color: "black" }}>
                Provident Fund Report
                {campusName != "" ? "( " + campusName + " )" : null}
              </h3>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "90%",
                }}
              >
                <h3 style={{ color: "black" }}>
                  Printing Date :- {todayDate()}
                </h3>
              </div>
            </div>
          </div>

          <table className="table mt-6">
            <thead>
              <tr className="text-center">
                <th>Code</th>
                <th>Name</th>
                <th>Scale</th>
                <th>Designation</th>
                <th>Account No</th>
                <th style={{ width: "160px" }}>Joining Date</th>
                <th>GPF Contribution</th>
                <th>GPF Deduction</th>
                <th>GPF Total</th>
              </tr>
            </thead>
            <tbody>
              {reportData.length > 0
                ? reportData.map(
                    (item, index) =>
                      item.campus_data.length > 0 &&
                      item.campus_data.map((subitem, subindex) => (
                        <React.Fragment key={index[subindex]}>
                          <tr>
                            <td
                              colSpan={5}
                              className="text-center text-primary"
                              style={{
                                fontSize: "1.3rem",
                                fontWeight: "500",
                                width: "max-content",
                                padding: "1px",
                                whiteSpace: "normal",
                                borderTop: "2px solid black",
                                borderBottom: "2px solid black",
                              }}
                            >
                              {item?.campus}
                            </td>
                            <td
                              colSpan={4}
                              className="text-center text-primary"
                              style={{
                                fontSize: "1.3rem",
                                fontWeight: "500",
                                width: "max-content",
                                padding: "1px",
                                whiteSpace: "normal",
                                borderTop: "2px solid black",
                                borderBottom: "2px solid black",
                              }}
                            >
                              {getMonthName(subitem?.month)}
                            </td>
                          </tr>
                          {subitem.month_data.length > 0
                            ? subitem.month_data.map(
                                (subsubitem, sub_sub_index) => (
                                  <tr key={sub_sub_index}>
                                    <td
                                      style={{
                                        padding: "1px",
                                        whiteSpace: "normal",
                                        color: "black",
                                      }}
                                    >
                                      {subsubitem?.employee?.emp_code}
                                    </td>
                                    <td
                                      style={{
                                        padding: "1px",
                                        whiteSpace: "normal",
                                        color: "black",
                                      }}
                                    >
                                      {subsubitem?.employee?.full_name}
                                    </td>
                                    <td
                                      style={{
                                        padding: "1px",
                                        whiteSpace: "normal",
                                        color: "black",
                                      }}
                                    >
                                      {
                                        subsubitem?.employee?.pay_scale
                                          ?.payscale
                                      }
                                    </td>
                                    <td
                                      style={{
                                        padding: "1px",
                                        whiteSpace: "normal",
                                        color: "black",
                                      }}
                                    >
                                      {subsubitem?.employee?.designation?.name}
                                    </td>
                                    <td
                                      style={{
                                        padding: "1px",
                                        whiteSpace: "normal",
                                        color: "black",
                                      }}
                                    >
                                      {subsubitem?.employee?.account_no != null
                                        ? subsubitem?.employee?.account_no
                                        : "----"}
                                    </td>
                                    <td
                                      style={{
                                        padding: "1px",
                                        whiteSpace: "normal",
                                        color: "black",
                                      }}
                                    >
                                      {subsubitem?.employee?.joining_date}
                                    </td>
                                    <td
                                      style={{
                                        padding: "1px",
                                        whiteSpace: "normal",
                                        color: "black",
                                      }}
                                    >
                                      {subsubitem?.gpf_contribution}
                                    </td>
                                    <td
                                      style={{
                                        padding: "1px",
                                        whiteSpace: "normal",
                                        color: "black",
                                      }}
                                    >
                                      {subsubitem?.gpf_deduction}
                                    </td>
                                    <td
                                      style={{
                                        padding: "1px",
                                        whiteSpace: "normal",
                                        color: "black",
                                      }}
                                    >
                                      {subsubitem?.gpf_total}
                                    </td>
                                  </tr>
                                )
                              )
                            : null}
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td
                              className="font-size-h6 font-weight-bolder text-black text-center text-primary"
                              style={{
                                borderBottom: "2px solid black",
                                borderTop: "2px solid black",
                                borderLeft: "2px solid black",
                                padding: "1px",
                                whiteSpace: "normal",
                              }}
                            >
                              Grand Total:-
                            </td>
                            <td
                              className="font-size-h6 font-weight-bolder text-black text-center"
                              style={{
                                borderBottom: "2px solid black",
                                borderTop: "2px solid black",
                                padding: "1px",
                                whiteSpace: "normal",
                              }}
                            >
                              {Intl.NumberFormat("en-Us").format(
                                subitem.month_data.reduce(
                                  (a, c) => a + parseInt(c.gpf_contribution),
                                  0
                                )
                              )}
                            </td>
                            <td
                              className="font-size-h6 font-weight-bolder text-black text-center"
                              style={{
                                borderBottom: "2px solid black",
                                borderTop: "2px solid black",
                                padding: "1px",
                                whiteSpace: "normal",
                              }}
                            >
                              {Intl.NumberFormat("en-Us").format(
                                subitem.month_data.reduce(
                                  (a, c) => a + parseInt(c.gpf_deduction),
                                  0
                                )
                              )}
                            </td>
                            <td
                              className="font-size-h6 font-weight-bolder text-black text-center"
                              style={{
                                borderBottom: "2px solid black",
                                borderTop: "2px solid black",
                                borderRight: "2px solid black",
                                padding: "1px",
                                whiteSpace: "normal",
                              }}
                            >
                              {Intl.NumberFormat("en-Us").format(
                                subitem.month_data.reduce(
                                  (a, c) => a + parseInt(c.gpf_total),
                                  0
                                )
                              )}
                            </td>
                          </tr>
                        </React.Fragment>
                      ))
                  )
                : null}
            </tbody>
          </table>
        </div>

        <div
          ref={tableRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          <table className="table">
            <thead>
              <tr className="text-center">
                <th>Code</th>
                <th>Name</th>
                <th>Scale</th>
                <th>Designation</th>
                <th>Account No</th>
                <th style={{ width: "160px" }}>Joining Date</th>
                <th>GPF Contribution</th>
                <th>GPF Deduction</th>
                <th>GPF Total</th>
              </tr>
            </thead>
            <tbody>
              {reportData.length > 0
                ? reportData.map(
                    (item, index) =>
                      item.campus_data.length > 0 &&
                      item.campus_data.map((subitem, subindex) => (
                        <React.Fragment key={index[subindex]}>
                          <tr>
                            <td
                              colSpan={5}
                              className="text-center text-primary"
                              style={{
                                fontSize: "1.3rem",
                                fontWeight: "500",
                                width: "max-content",
                                padding: "1px",
                                whiteSpace: "normal",
                                borderTop: "2px solid black",
                                borderBottom: "2px solid black",
                              }}
                            >
                              {item?.campus}
                            </td>
                            <td
                              colSpan={4}
                              className="text-center text-primary"
                              style={{
                                fontSize: "1.3rem",
                                fontWeight: "500",
                                width: "max-content",
                                padding: "1px",
                                whiteSpace: "normal",
                                borderTop: "2px solid black",
                                borderBottom: "2px solid black",
                              }}
                            >
                              {getMonthName(subitem?.month)}
                            </td>
                          </tr>
                          {subitem.month_data.length > 0
                            ? subitem.month_data.map(
                                (subsubitem, sub_sub_index) => (
                                  <tr key={sub_sub_index}>
                                    <td
                                      style={{
                                        padding: "1px",
                                        whiteSpace: "normal",
                                        color: "black",
                                      }}
                                    >
                                      {subsubitem?.employee?.emp_code}
                                    </td>
                                    <td
                                      style={{
                                        padding: "1px",
                                        whiteSpace: "normal",
                                        color: "black",
                                      }}
                                    >
                                      {subsubitem?.employee?.full_name}
                                    </td>
                                    <td
                                      style={{
                                        padding: "1px",
                                        whiteSpace: "normal",
                                        color: "black",
                                      }}
                                    >
                                      {
                                        subsubitem?.employee?.pay_scale
                                          ?.payscale
                                      }
                                    </td>
                                    <td
                                      style={{
                                        padding: "1px",
                                        whiteSpace: "normal",
                                        color: "black",
                                      }}
                                    >
                                      {subsubitem?.employee?.designation?.name}
                                    </td>
                                    <td
                                      style={{
                                        padding: "1px",
                                        whiteSpace: "normal",
                                        color: "black",
                                      }}
                                    >
                                      {subsubitem?.employee?.account_no != null
                                        ? subsubitem?.employee?.account_no
                                        : "----"}
                                    </td>
                                    <td
                                      style={{
                                        padding: "1px",
                                        whiteSpace: "normal",
                                        color: "black",
                                      }}
                                    >
                                      {subsubitem?.employee?.joining_date}
                                    </td>
                                    <td
                                      style={{
                                        padding: "1px",
                                        whiteSpace: "normal",
                                        color: "black",
                                      }}
                                    >
                                      {subsubitem?.gpf_contribution}
                                    </td>
                                    <td
                                      style={{
                                        padding: "1px",
                                        whiteSpace: "normal",
                                        color: "black",
                                      }}
                                    >
                                      {subsubitem?.gpf_deduction}
                                    </td>
                                    <td
                                      style={{
                                        padding: "1px",
                                        whiteSpace: "normal",
                                        color: "black",
                                      }}
                                    >
                                      {subsubitem?.gpf_total}
                                    </td>
                                  </tr>
                                )
                              )
                            : null}
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td
                              className="font-size-h6 font-weight-bolder text-black text-center text-primary"
                              style={{
                                borderBottom: "2px solid black",
                                borderTop: "2px solid black",
                                borderLeft: "2px solid black",
                                padding: "1px",
                                whiteSpace: "normal",
                              }}
                            >
                              Grand Total:-
                            </td>
                            <td
                              className="font-size-h6 font-weight-bolder text-black text-center"
                              style={{
                                borderBottom: "2px solid black",
                                borderTop: "2px solid black",
                                padding: "1px",
                                whiteSpace: "normal",
                              }}
                            >
                              {Intl.NumberFormat("en-Us").format(
                                subitem.month_data.reduce(
                                  (a, c) => a + parseInt(c.gpf_contribution),
                                  0
                                )
                              )}
                            </td>
                            <td
                              className="font-size-h6 font-weight-bolder text-black text-center"
                              style={{
                                borderBottom: "2px solid black",
                                borderTop: "2px solid black",
                                padding: "1px",
                                whiteSpace: "normal",
                              }}
                            >
                              {Intl.NumberFormat("en-Us").format(
                                subitem.month_data.reduce(
                                  (a, c) => a + parseInt(c.gpf_deduction),
                                  0
                                )
                              )}
                            </td>
                            <td
                              className="font-size-h6 font-weight-bolder text-black text-center"
                              style={{
                                borderBottom: "2px solid black",
                                borderTop: "2px solid black",
                                borderRight: "2px solid black",
                                padding: "1px",
                                whiteSpace: "normal",
                              }}
                            >
                              {Intl.NumberFormat("en-Us").format(
                                subitem.month_data.reduce(
                                  (a, c) => a + parseInt(c.gpf_total),
                                  0
                                )
                              )}
                            </td>
                          </tr>
                        </React.Fragment>
                      ))
                  )
                : null}
            </tbody>
          </table>
        </div>
      </Paper>
    </>
  );
};

export default ProvidentFundReport;
