import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import React, { useEffect } from "react";
import "./card.result.css";
import { upperCase } from "lodash";

const pic = "https://www.colorhexa.com/a4dded.png";
// const logo =
// "https://darearqam.com/wp-content/uploads/2020/03/daraarqam-logo.jpg";

function ResultCard({
  studentResultList,
  studentFinalResult,
  studentDetails,
  campusDetail,
  student_result_overall,
  isInclude,
  currentExam,
  icon,
  unitName,
  weekList,
  studentResultGradeWise,
}) {
  console.log("hassan testing", unitName);
  useEffect(() => {
    if (Array.isArray(student_result_overall)) {
      const filtered = student_result_overall.filter(
        (item) => item.exam == "FIRST TERM"
      );
      student_result_overall = filtered;
    }
    console.log(student_result_overall);
  }, [student_result_overall]);

  const getTotalNumber = (array) => {
    let sum = 0;

    array.forEach((element) => {
      sum += parseInt(element.gain_marks);
    });

    return sum;
  };

  const getPracticalTotalNumber = (array) => {
    console.log("getPracticalTotalNumber", array);
    let sum = 0;

    array.forEach((element) => {
      sum += parseInt(element.practical_marks);
    });

    return sum;
  };

  const getGrade = (fullmarks, gainmarks) => {
    let obtainMarks = gainmarks / fullmarks;
    let middle = obtainMarks * 100;
    let percentage = middle.toFixed(2);

    if (percentage <= 100 && percentage >= 90) {
      return "A+";
    } else if (percentage < 90 && percentage >= 80) {
      return "A";
    } else if (percentage < 80 && percentage >= 70) {
      return "B";
    } else if (percentage < 70 && percentage >= 60) {
      return "C";
    } else if (percentage < 60 && percentage >= 50) {
      return "D";
    } else if (percentage < 50 && percentage >= 40) {
      return "E";
    } else if (percentage < 40 && percentage >= 0) {
      return "F";
    } else {
      return "--";
    }
  };

  const getPercentage = (totalmarks, gainMarks) => {
    const percentage = (gainMarks / totalmarks) * 100;
    return percentage.toFixed(1) + "%";
  };

  const getMonthName = (date) => {
    const monthNames = [
      "January", // index 0
      "February", // index 1
      "March", // index 2
      "April", // index 3
      "May", // index 4
      "June", // index 5
      "July", // index 6
      "August", // index 7
      "September", // index 8
      "October", // index 9
      "November", // index 10
      "December", // index 11
    ];
    const d = new Date(date);

    return upperCase(`${monthNames[d.getMonth()]}, ${d.getFullYear()}`);
  };

  // Function to get the name by week_id
  const getWeekNameById = (week_id) => {
    const week = weekList.find((w) => w.id == week_id);
    return week ? week.name : "Unknown Week"; // Return name or a default value
  };

  return (
    <table style={{ margin: "0 auto" }}>
      <tbody>
        <tr>
          <td>
            <table
              className="result__card__table"
              border="2"
              cellpadding="0"
              cellspacing="0"
              width="100%"
              style={{ width: "1100px" }}
            >
              <tbody>
                <tr>
                  <td style={{ width: "118px", textAlign: "center" }}>
                    <img className="p-1" id="Image1" src={icon} width={100} />
                  </td>
                  <td style={{ width: "457", textAlignLast: "center" }}>
                    <span id="LblOIDName" className="AName">
                      {unitName}
                    </span>
                    <br />
                    <span id="LblTermName" className="AName1">
                      RESULT CARD
                    </span>
                    <br />
                    <span id="LblTermName" className="AName2">
                      {campusDetail?.exam?.exam_type?.name}
                      {campusDetail?.exam?.week_id != null
                        ? campusDetail?.exam?.month != null
                          ? " " +
                            "( " +
                            getWeekNameById(campusDetail?.exam?.week_id) +
                            " OF " +
                            getMonthName(campusDetail?.exam?.month) +
                            " )"
                          : " " +
                            "( " +
                            getWeekNameById(campusDetail?.exam?.week_id) + // Use the week name only
                            " )"
                        : campusDetail?.exam?.month != null
                        ? " " +
                          "( " +
                          getMonthName(campusDetail?.exam?.month) +
                          " )"
                        : ""}
                    </span>
                  </td>
                  <td
                    style={{ width: "125px", textAlign: "center" }}
                    rowSpan={2}
                  >
                    <img
                      id="StudentImage"
                      src={studentDetails?.picture || pic}
                      style={{ height: 135, width: 124, marginRight: 15 }}
                    />
                  </td>
                </tr>

                <>
                  <tr>
                    <td colSpan="2">
                      <table
                        border="0"
                        cellpadding="5"
                        cellspacing="0"
                        width="100%"
                      >
                        <tbody>
                          <tr>
                            <td
                              className="BoldText"
                              style={{
                                width: "145px",
                                textAlign: "right",
                                color: "#000080",
                              }}
                            >
                              Admission ID :-
                            </td>
                            <td
                              style={{
                                width: "330px",
                                textAlign: "start",
                                color: "#000080",
                              }}
                            >
                              <span id="LblAdmissionID" className="BoldText">
                                {studentDetails?.admission_id}
                              </span>
                            </td>
                            <td
                              className="LabelStyle"
                              style={{
                                width: "90px",
                                textAlign: "right",
                                color: "#000080",
                              }}
                            >
                              Session :-
                            </td>
                            <td
                              align="Center"
                              style={{
                                width: "135px",
                                textAlign: "left",
                                color: "#000080",
                              }}
                            >
                              <span id="LblSession" className="NormalText">
                                {campusDetail?.exam?.session?.year}{" "}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <table
                        border="1"
                        cellPadding="5"
                        cellSpacing="0"
                        width="100%"
                      >
                        <tbody>
                          <tr>
                            <td
                              className="BoldText"
                              style={{
                                width: "125px",
                                border: "1px solid black",
                                color: "#000080",
                              }}
                            >
                              Name :-
                            </td>
                            <td
                              style={{
                                width: "575px",
                                border: "1px solid black",
                                textAlign: "start",
                                color: "#000080",
                              }}
                              colSpan="3"
                            >
                              <span id="LblName" className="NormalText">
                                {studentDetails?.name}{" "}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              className="BoldText"
                              style={{
                                width: "125px",
                                border: "1px solid black",
                                color: "#000080",
                              }}
                            >
                              Father's Name :
                            </td>
                            <td
                              colSpan="3"
                              style={{
                                width: "575px",
                                border: "1px solid black",
                                textAlign: "start",
                                color: "#000080",
                              }}
                            >
                              <span id="LblFather" className="NormalText">
                                {studentDetails?.father_name}{" "}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              className="BoldText"
                              style={{
                                width: "125px",
                                border: "1px solid black",
                              }}
                            >
                              Class :-
                            </td>
                            <td
                              style={{
                                width: "240px",
                                border: "1px solid black",
                                textAlign: "start",
                                color: "#000080",
                              }}
                            >
                              <span id="LblClass" className="NormalText">
                                {studentDetails?.student_class?.name}{" "}
                              </span>
                            </td>
                            <td
                              className="LabelStyle"
                              style={{
                                width: "80px",
                                border: "1px solid black",
                              }}
                            >
                              Section :-
                            </td>
                            <td
                              align="Center"
                              style={{
                                width: "255px",
                                textAlign: "left",
                                border: "1px solid black",
                                textAlign: "start",
                                color: "#000080",
                              }}
                            >
                              <span id="LblSection" className="NormalText">
                                {studentResultList[0]?.global_section?.name ||
                                  campusDetail?.global_section?.name}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              colspan="4"
                              style={{
                                border: "1px solid black",
                                textAlign: "start",
                              }}
                            >
                              <span id="LblCourse" className="NormalText">
                                Detail of Marks Obtained
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <table
                                border="0"
                                cellPadding="0"
                                cellSpacing="0"
                                width="100%"
                              >
                                <tbody>
                                  <tr>
                                    <td>
                                      <div>
                                        <table
                                          className="GridStyleResultCard"
                                          cellSpacing="0"
                                          rules="all"
                                          border="2"
                                          id="GridView1"
                                          style={{
                                            width: "100%",
                                            borderCollapse: "collapse",
                                          }}
                                        >
                                          <tbody>
                                            <tr
                                              className="GridHeaderStyle"
                                              style={{
                                                border: "1px solid black",
                                              }}
                                            >
                                              <th
                                                style={{
                                                  border: "1px solid black",
                                                  color: "#000080",
                                                }}
                                                scope="col"
                                              >
                                                SUBJECTS
                                              </th>
                                              <th
                                                style={{
                                                  border: "1px solid black",
                                                  width: "110px",
                                                  color: "#000080",
                                                }}
                                                scope="col"
                                              >
                                                Total Marks
                                              </th>
                                              <th
                                                style={{
                                                  border: "1px solid black",
                                                  color: "#000080",
                                                }}
                                                scope="col"
                                              >
                                                Theory
                                              </th>
                                              <th
                                                style={{
                                                  border: "1px solid black",
                                                  color: "#000080",
                                                }}
                                                scope="col"
                                              >
                                                Presentation
                                              </th>
                                              <th
                                                style={{
                                                  border: "1px solid black",
                                                  color: "#000080",
                                                }}
                                                scope="col"
                                              >
                                                Total Gain
                                              </th>
                                              <th
                                                style={{
                                                  border: "1px solid black",
                                                  color: "#000080",
                                                }}
                                                scope="col"
                                              >
                                                %
                                              </th>
                                              <th
                                                style={{
                                                  border: "1px solid black",
                                                  color: "#000080",
                                                }}
                                                scope="col"
                                              >
                                                Grade
                                              </th>
                                              <th
                                                style={{
                                                  border: "1px solid black",
                                                  color: "#000080",
                                                }}
                                                scope="col"
                                              >
                                                Remarks
                                              </th>
                                            </tr>
                                            {studentResultList &&
                                              studentResultList.map(
                                                (onePersonResult, index) => (
                                                  <tr className="GridRowStyle">
                                                    <td
                                                      // valign="middle"
                                                      style={{
                                                        width: "300px",
                                                        border:
                                                          "1px solid black",
                                                        textAlign: "start",
                                                        color: "#000080",
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      {
                                                        onePersonResult?.subject
                                                          ?.name
                                                      }{" "}
                                                    </td>
                                                    <td
                                                      align="center"
                                                      // valign="middle"
                                                      style={{
                                                        width: "90px",
                                                        border:
                                                          "1px solid black",
                                                        textAlign: "center",
                                                        color: "#000080",
                                                      }}
                                                    >
                                                      {
                                                        onePersonResult?.full_marks
                                                      }
                                                    </td>
                                                    <td
                                                      align="center"
                                                      // valign="middle"
                                                      style={{
                                                        width: "90px",
                                                        border:
                                                          "1px solid black",
                                                        textAlign: "center",
                                                        color: "#000080",
                                                      }}
                                                    >
                                                      {onePersonResult.status ==
                                                      "Present"
                                                        ? onePersonResult.gain_marks
                                                        : onePersonResult.status}
                                                    </td>
                                                    <td
                                                      style={{
                                                        width: "90px",
                                                        border:
                                                          "1px solid black",
                                                        textAlign: "center",
                                                        color: "#000080",
                                                      }}
                                                    >
                                                      {onePersonResult?.practical_marks ==
                                                      0
                                                        ? "--"
                                                        : onePersonResult?.practical_marks}
                                                    </td>
                                                    <td
                                                      align="center"
                                                      // valign="middle"
                                                      style={{
                                                        width: "60px",
                                                        border:
                                                          "1px solid black",
                                                        textAlign: "center",
                                                        color: "#000080",
                                                      }}
                                                    >
                                                      {Number(
                                                        onePersonResult.gain_marks
                                                      ) +
                                                        Number(
                                                          onePersonResult?.practical_marks
                                                        )}
                                                    </td>
                                                    <td
                                                      align="center"
                                                      // valign="middle"
                                                      style={{
                                                        width: "60px",
                                                        border:
                                                          "1px solid black",
                                                        textAlign: "center",
                                                        color: "#000080",
                                                      }}
                                                    >
                                                      {
                                                        onePersonResult?.percentage
                                                      }
                                                      %
                                                    </td>
                                                    <td
                                                      align="center"
                                                      // valign="middle"
                                                      style={{
                                                        width: "60px",
                                                        border:
                                                          "1px solid black",
                                                        textAlign: "center",
                                                        color: "#000080",
                                                      }}
                                                    >
                                                      {onePersonResult?.grade}
                                                    </td>
                                                    <td
                                                      align="center"
                                                      // valign="middle"
                                                      style={{
                                                        width: "80px",
                                                        border:
                                                          "1px solid black",
                                                        textAlign: "center",
                                                        color: "#000080",
                                                      }}
                                                    >
                                                      {onePersonResult?.grade ==
                                                        "F" ||
                                                      onePersonResult?.grade ==
                                                        "F+"
                                                        ? "FAIL"
                                                        : "PASS"}
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  {isInclude ? (
                    <>
                      <tr>
                        <td colSpan="3" align="center">
                          <div
                            style={{
                              borderRight: "1px solid black",
                              borderTop: "1px solid black",
                              borderLeft: "1px solid black",
                              // borderBottom: "1px solid black",
                            }}
                          >
                            <table
                              id="TblFail"
                              border="0"
                              cellPadding="0"
                              cellSpacing="0"
                              width="100%"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    className="BoldText"
                                    style={{
                                      width: "390px",
                                      color: "#000080",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {currentExam?.exam}
                                  </td>
                                  <td
                                    style={{ width: "145px", color: "#000080" }}
                                  >
                                    {currentExam?.full_marks}
                                  </td>

                                  <td
                                    style={{ width: "105px", color: "#000080" }}
                                  >
                                    {currentExam?.gain_marks}
                                  </td>

                                  {currentExam?.fail_in.length > 0 &&
                                  currentExam?.full_marks > 0 ? (
                                    <>
                                      <td
                                        style={{
                                          color: "#000080",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {currentExam?.fail_in.length > 0
                                          ? // Annual Term
                                            "Failed In"
                                          : ""}
                                        &nbsp;&nbsp;
                                        {currentExam?.fail_in.length > 0
                                          ? currentExam?.fail_in.map(
                                              (overvall, i) => (
                                                <span
                                                  style={{
                                                    color: "#000080",
                                                    alignSelf: "flex-end",
                                                  }}
                                                  key={i + 1}
                                                  id="LblGazres"
                                                  className="BoldText"
                                                >
                                                  {overvall.substr(0, 3)}
                                                  {","}&nbsp;
                                                </span>
                                              )
                                            )
                                          : null}
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      <td
                                        style={{
                                          width: "130px",
                                          color: "#000080",
                                        }}
                                      >
                                        {currentExam?.practical_marks == 0
                                          ? "--"
                                          : currentExam?.practical_marks}
                                      </td>
                                      <td
                                        style={{
                                          width: "65px",
                                          color: "#000080",
                                        }}
                                      >
                                        {currentExam?.practical_marks +
                                          currentExam?.gain_marks}
                                      </td>
                                      <td
                                        style={{
                                          width: "85px",
                                          color: "#000080",
                                        }}
                                      >
                                        {currentExam?.percentage}
                                      </td>
                                      <td
                                        id="forgrade"
                                        style={{
                                          width: "69px",
                                          color: "#000080",
                                        }}
                                      >
                                        {currentExam?.grade}
                                      </td>
                                      <td style={{ color: "#000080" }}>
                                        {/* Current Exam Pass/Fail Logic */}
                                        {currentExam?.grade == "F"
                                          ? "FAIL"
                                          : "PASS"}
                                      </td>
                                    </>
                                  )}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td colSpan="3" align="center">
                          <div
                            style={{
                              borderRight: "1px solid black",
                              borderTop: "1px solid black",
                              borderLeft: "1px solid black",
                              // borderBottom: "1px solid black",
                            }}
                          >
                            <table
                              id="TblFail"
                              border="0"
                              cellPadding="0"
                              cellSpacing="0"
                              width="100%"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    className="BoldText"
                                    style={{
                                      width: "390px",
                                      color: "#000080",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {student_result_overall.exam}
                                  </td>
                                  <td
                                    style={{ width: "145px", color: "#000080" }}
                                  >
                                    {student_result_overall?.full_marks}
                                  </td>

                                  <td
                                    style={{ width: "105px", color: "#000080" }}
                                  >
                                    {student_result_overall?.gain_marks}
                                  </td>

                                  {student_result_overall?.fail_in.length > 0 &&
                                  student_result_overall?.full_marks > 0 ? (
                                    <>
                                      <td
                                        style={{
                                          color: "#000080",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {// First Term Result
                                        student_result_overall?.fail_in.length >
                                        0
                                          ? ""
                                          : ""}
                                        &nbsp;&nbsp;
                                        {/* Comment the code for not showing the failed subjects for first term result */}
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      <td
                                        style={{
                                          width: "130px",
                                          color: "#000080",
                                        }}
                                      >
                                        {student_result_overall.practical_marks ==
                                        0
                                          ? "--"
                                          : student_result_overall.practical_marks}
                                      </td>
                                      <td
                                        style={{
                                          width: "65px",
                                          color: "#000080",
                                        }}
                                      >
                                        {student_result_overall.practical_marks +
                                          student_result_overall?.gain_marks}
                                      </td>
                                      <td
                                        style={{
                                          width: "85px",
                                          color: "#000080",
                                        }}
                                      >
                                        {student_result_overall?.percentage}
                                      </td>
                                      <td
                                        id="forgrade"
                                        style={{
                                          width: "69px",
                                          color: "#000080",
                                        }}
                                      >
                                        {student_result_overall.grade}
                                      </td>
                                      <td style={{ paddingRight: "5px" }}>
                                        {/* First Term Pass/Fail Logic */}

                                        {""}
                                      </td>
                                    </>
                                  )}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td colSpan="3" align="center">
                          <div
                            style={{
                              borderRight: "1px solid black",
                              borderTop: "1px solid black",
                              borderLeft: "1px solid black",
                              borderBottom: "1px solid black",
                            }}
                          >
                            <table
                              id="TblFail"
                              border="0"
                              cellPadding="0"
                              cellSpacing="0"
                              width="100%"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    className="BoldText"
                                    style={{
                                      width: "356px",
                                      color: "#000080",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    OVER ALL RESULT
                                  </td>
                                  <td
                                    style={{ width: "130px", color: "#000080" }}
                                  >
                                    {
                                      // Overall Total Marks
                                      studentFinalResult?.full_marks
                                    }
                                  </td>
                                  <td
                                    style={{ width: "104px", color: "#000080" }}
                                  >
                                    {
                                      // Overall Gain Marks
                                      studentFinalResult?.gain_marks
                                    }
                                  </td>
                                  <td
                                    style={{ width: "105px", color: "#000080" }}
                                  >
                                    {// Overall Practical Marks
                                    studentFinalResult?.practical_marks == 0
                                      ? "--"
                                      : studentFinalResult?.practical_marks}
                                  </td>
                                  <td
                                    style={{ width: "70px", color: "#000080" }}
                                  >
                                    {// Overall Total Gain Marks
                                    studentFinalResult?.gain_marks +
                                      studentFinalResult?.practical_marks}
                                  </td>
                                  <td
                                    style={{ width: "71px", color: "#000080" }}
                                  >
                                    {
                                      // Overall Percentage
                                      studentFinalResult?.percentage
                                    }
                                  </td>
                                  <td
                                    id="forgrade"
                                    style={{ width: "69px", color: "#000080" }}
                                  >
                                    {
                                      // Overall Grade Showing
                                      studentFinalResult?.grade
                                    }
                                  </td>

                                  {/* {// Overall new pass/fail logic
                                  currentExam?.fail_in.length >= 2 ? ( */}
                                  <td
                                    style={{
                                      width: "98px",
                                      color: "#000080",
                                    }}
                                  >
                                    {studentFinalResult?.remarks}
                                    {""}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>
                    </>
                  ) : (
                    <tr>
                      <td colSpan="3" align="center">
                        <div
                          style={{
                            borderRight: "1px solid black",
                            borderTop: "1px solid black",
                            borderLeft: "1px solid black",
                            borderBottom: "1px solid black",
                          }}
                        >
                          <table
                            id="TblFail"
                            border="0"
                            cellPadding="0"
                            cellSpacing="0"
                            width="100%"
                          >
                            <tbody>
                              <tr>
                                <td
                                  className="BoldText"
                                  style={{
                                    width: "356px",
                                    color: "#000080",
                                    fontWeight: "bold",
                                  }}
                                >
                                  OVER ALL RESULT
                                </td>
                                <td
                                  style={{ width: "130px", color: "#000080" }}
                                >
                                  {student_result_overall?.full_marks}
                                </td>

                                <td
                                  style={{ width: "104px", color: "#000080" }}
                                >
                                  {student_result_overall?.gain_marks}
                                </td>

                                {student_result_overall?.fail_in.length > 0 ? (
                                  <>
                                    <td
                                      style={{
                                        width: "105px",
                                        color: "#000080",
                                      }}
                                    >
                                      {student_result_overall?.practical_marks ==
                                      0
                                        ? "--"
                                        : student_result_overall?.practical_marks}
                                    </td>
                                    <td
                                      style={{
                                        width: "70px",
                                        color: "#000080",
                                      }}
                                    >
                                      {student_result_overall?.gain_marks +
                                        student_result_overall?.practical_marks}
                                    </td>
                                    <td
                                      style={{
                                        color: "#000080",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {student_result_overall?.fail_in.length >
                                      0
                                        ? "Failed In"
                                        : ""}
                                      &nbsp;&nbsp;
                                      {student_result_overall?.fail_in.length >
                                      0
                                        ? student_result_overall?.fail_in.map(
                                            (overvall, i) => (
                                              <span
                                                style={{
                                                  color: "#000080",
                                                  alignSelf: "flex-end",
                                                }}
                                                key={i + 1}
                                                id="LblGazres"
                                                className="BoldText"
                                              >
                                                {overvall.substr(0, 3)}
                                                {","}&nbsp;
                                              </span>
                                            )
                                          )
                                        : null}
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td
                                      style={{
                                        width: "105px",
                                        color: "#000080",
                                      }}
                                    >
                                      {student_result_overall?.practical_marks ==
                                      0
                                        ? "--"
                                        : student_result_overall?.practical_marks}
                                    </td>
                                    <td
                                      style={{
                                        width: "70px",
                                        color: "#000080",
                                      }}
                                    >
                                      {student_result_overall?.gain_marks +
                                        student_result_overall?.practical_marks}
                                    </td>
                                    <td
                                      style={{
                                        width: "75px",
                                        color: "#000080",
                                      }}
                                    >
                                      {student_result_overall?.percentage}
                                    </td>
                                    <td
                                      id="forgrade"
                                      style={{
                                        width: "69px",
                                        color: "#000080",
                                      }}
                                    >
                                      {student_result_overall?.grade}
                                    </td>
                                    <td
                                      style={{
                                        paddingRight: "5px",
                                        width: "95px",
                                        color: "#000080",
                                      }}
                                    >
                                      {student_result_overall?.remarks}
                                    </td>
                                  </>
                                )}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td colSpan="3" style={{ textAlign: "left" }}>
                      <br />
                      <table
                        border="0"
                        cellPadding="0"
                        cellSpacing="0"
                        width="100%"
                      >
                        <tbody>
                          <tr>
                            <td style={{ width: "60%", verticalAlign: "top" }}>
                              <table
                                id="DataList1"
                                className="NormalText"
                                cellSpacing="0"
                                style={{ borderCollapse: "collapse" }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        color: "#000080",
                                        textAlign: "start",
                                      }}
                                    >
                                      90% - Above A+ OutStanding.
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        color: "#000080",
                                        textAlign: "start",
                                      }}
                                    >
                                      80% - 89.99% A Excellent.
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        color: "#000080",
                                        textAlign: "start",
                                      }}
                                    >
                                      70% - 79.99% B V.Good.
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        color: "#000080",
                                        textAlign: "start",
                                      }}
                                    >
                                      60% - 69.99% C Good.
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        color: "#000080",
                                        textAlign: "start",
                                      }}
                                    >
                                      50% - 59.99% D Fair.
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        color: "#000080",
                                        textAlign: "start",
                                      }}
                                    >
                                      40% - 49.99% E Weak.
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        color: "#000080",
                                        textAlign: "start",
                                      }}
                                    >
                                      0% - 39.99% F Fail.
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td style={{ width: "40%", verticalAlign: "top" }}>
                              {studentResultGradeWise &&
                              studentResultGradeWise.length > 0 ? (
                                <table
                                  className="GridStyleResultCard"
                                  id="DataList1"
                                  // className="NormalText"
                                  cellSpacing="0"
                                  style={{
                                    borderCollapse: "collapse",
                                    width: "60%",
                                  }}
                                >
                                  <thead>
                                    <th
                                      style={{
                                        border: "1px solid black",
                                        color: "#000080",
                                      }}
                                      scope="col"
                                    >
                                      SUBJECTS
                                    </th>
                                    <th
                                      style={{
                                        border: "1px solid black",
                                        color: "#000080",
                                      }}
                                      scope="col"
                                    >
                                      Grade
                                    </th>
                                  </thead>
                                  <tbody>
                                    {studentResultGradeWise &&
                                      studentResultGradeWise?.map(
                                        (item, index) => (
                                          <tr key={index}>
                                            <td
                                              style={{
                                                color: "#000080",
                                                border: "1px solid black",
                                                textAlign: "start",
                                                paddingLeft: "10px",
                                              }}
                                            >
                                              {item?.subject?.name}
                                            </td>
                                            <td
                                              style={{
                                                color: "#000080",
                                                border: "1px solid black",
                                                textAlign: "center",
                                              }}
                                            >
                                              {item?.grade}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                  </tbody>
                                </table>
                              ) : null}
                            </td>
                          </tr>
                          {/* <tr>
                            <td colSpan={2} style={{ verticalAlign: "top" }}>
                              <table
                                id="DataList2"
                                className="NormalText"
                                cellSpacing="0"
                                style={{ borderCollapse: "collapse" }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        color: "#000080",
                                        textAlign: "justify",
                                      }}
                                    >
                                      Note.1 In Urdu, English, Math and Science,
                                      those students who will fail in any two
                                      subjects will be considered fail.
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        color: "#000080",
                                        textAlign: "justify",
                                      }}
                                    >
                                      Note.2 Those students who will fail in one
                                      subject mentioned above and will fail in
                                      other two subjects i.e.(Islamiat, S.
                                      Studies, Arabic, Drawing/H. Eco.) will be
                                      considered fail.
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        color: "#000080",
                                        textAlign: "justify",
                                      }}
                                    >
                                      Note.3 In 8th Class fail in any two
                                      subjects should be considered fail.
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr> */}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ textAlign: "Right" }}
                      className="BoldText"
                    ></td>
                    <td
                      style={{
                        textAlign: "Right",
                        color: "#000080",
                      }}
                      className="BoldText"
                    >
                      <br />
                      <br />
                      <br />
                      _____________________
                      <br />
                      Controller of Examinations
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <div style={{ borderBottom: "1px solid black" }}>
                        <br />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <br />
                      <div style={{ border: "1px solid black" }}>
                        <table
                          border="0"
                          cellPadding="5"
                          cellSpacing="0"
                          width="100%"
                        >
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                }}
                              >
                                <span
                                  style={{ fontSize: "1.4rem" }}
                                  id="lblAddress1"
                                  className="BoldText"
                                >
                                  {campusDetail?.campus?.name}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default ResultCard;
