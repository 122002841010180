import { Paper } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { baseUrl } from "../../../services/config";
import { Spinner, Table } from "react-bootstrap";
import { useAlert } from "react-alert";
import ReactToPrint from "react-to-print";
import "./checkList.css";
import { useRef } from "react";
import ExportIt from "./ExportIt";
import { upperCase } from "lodash";
import { useSelector } from "react-redux";

const CheckList = () => {
  useEffect(() => {
    getSettingData();
    // getAllCampus();
    sessions();
  }, []);

  const [selectedcampus, setselectedcampus] = useState("");
  const [classes, setClasses] = useState([]);
  const user = useSelector((state) => state.user.user);
  const [campuses, setallcampuses] = useState(user.campuses || []);
  const [selectedClass, setSelectedClass] = useState("");
  const [sectionData, setSectionData] = useState([]);
  const [selectedSection, setselectedsection] = useState("");
  const [sessionData, setSessionData] = useState([]);
  const [reportData, setReportData] = useState({});
  const [yearId, setYearId] = useState("");
  const [loading, setloading] = useState(false);
  const [grandTotal, setGrandTotal] = useState(0);
  const [fullSession, setFullSession] = useState({});
  const [educationType, setEducationType] = useState(1);
  const alert = useAlert();
  const authtoken = localStorage.getItem("userToken");
  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const pageStyle = `
    @page {
  margin-left: 1.5in;
      size: legal landscape !important;
  }
  `;
  const [isVisible, setIsVisible] = useState(false);
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);
  const [includeFee, setIncludeFee] = useState(false);

  const sessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            console.log(session);
            setFullSession(session);
            setYearId(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCampus = async (e) => {
    let id;
    if (e) {
      id = e.target.value;
      setselectedcampus(id);
      onChampusChange(id);
    }
  };

  const onChampusChange = (id) => {
    fetch(`${baseUrl}/campusclass/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.payload) {
          setClasses(data.payload);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "yrr q nahi show ho rahy");
        setallcampuses(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };
  const getClasswiseStudent = (e) => {
    let id = e.target.value;
    onClassChange(id);
    setSelectedClass(id);
  };
  const onClassChange = (id) => {
    fetch(`${baseUrl}/class_section/${selectedcampus}/${educationType}/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSectionData(data.payload);
      })
      .catch((err) => {
        console.log("", err);
      });
  };

  const getSectionsListOnTypeChange = (id) => {
    fetch(
      `${baseUrl}/class_section/${selectedcampus}/${id}${
        selectedClass ? "/" + selectedClass : ""
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => {
        res.json().then((data) => {
          console.log(data, "section data");
          setSectionData(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onSectionChange = (id) => {
    setselectedsection(id);
  };
  const handleShow = () => {
    if (selectedcampus == "") {
      alert.error("Please Select The Campus");
      return;
    }
    let data = {
      campus_id: selectedcampus,
      class_id: selectedClass,
      section_id: selectedSection,
      education_type: educationType,
      year_id: yearId,
      include_fee: includeFee ? 0 : 1,
    };
    setloading(true);

    fetch(`${baseUrl}/student_check_list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          setloading(false);
          console.log("Checklist Status", data.payload);
          if (data.metadata) {
            if (data.metadata.success) {
              let sum = 0;
              data.payload.campus_data.map((item) => {
                item.sections.map((subitem) => {
                  subitem.students.map((student) => {
                    sum += parseInt(student.monthly_fees);
                  });
                });
              });
              setGrandTotal(sum);
              setReportData(data.payload);
            }
          }
        })
      )

      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };
  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  let num = 0;
  let num2 = 0;
  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row mt-2">
          <div className="col-md-3">
            <label>
              <b>Select Session</b>
            </label>
            <div className="d-flex flex-column ">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                value={yearId}
                aria-label="Default select example"
                onChange={(e) => {
                  const selectedObject = sessionData.find(
                    (option) => option.id == e.target.value
                  );
                  setFullSession(selectedObject);
                  setYearId(e.target.value);
                }}
              >
                <option value="">Select Session</option>
                {sessionData &&
                  sessionData.map((item) => (
                    <option value={item.id}>{item.year}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Campus</b>
            </label>
            <select
              className="form-select"
              name="campus_id"
              // value={campuses.length == 0 ? "" : previousselectedcampus}
              onChange={(e) => handleCampus(e)}
              style={{ height: 40 }}
              aria-label="Default select example"
            >
              <option value=""> Select Campus</option>
              {campuses.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label>
              <b>Select Class</b>
            </label>
            <select
              className="form-select"
              name="campus_id"
              // value={campuses.length == 0 ? "" : previousselectedcampus}
              style={{ height: 40 }}
              aria-label="Default select example"
              onChange={(e) => getClasswiseStudent(e)}
            >
              <option value=""> Select Class</option>
              {classes &&
                classes.map((sclass, index) => (
                  <>
                    <option key={index} value={sclass.id}>
                      {sclass.name}
                    </option>
                  </>
                ))}
            </select>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Education Type</b>
            </label>
            <select
              className="form-select"
              name="campus_id"
              value={educationType}
              style={{ height: 40 }}
              aria-label="Default select example"
              onChange={(e) => {
                setEducationType(e.target.value);
                getSectionsListOnTypeChange(e.target.value);
              }}
            >
              <option selected value={1}>
                Education
              </option>
              <option value={2}>Hifz</option>
            </select>
          </div>
          <div className="col-md-3 mt-3">
            <label>
              <b>Select Section</b>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="campus_id"
              aria-label="Default select example"
              onChange={(e) => onSectionChange(e.target.value)}
            >
              <option value=""> Section</option>

              {sectionData &&
                sectionData?.map((item) => (
                  <option value={item.id}>{item.name}</option>
                ))}
            </select>
          </div>
          <div className="col-md-4 mt-8 d-flex">
            <div style={{ height: 40 }} className="col-md-6 mt-4">
              <input type="checkbox" style={{ display: "none" }} />
              <label className="checkbox checkbox-single mt-4">
                <input
                  type="checkbox"
                  onChange={(e) => setIncludeFee(e.target.checked)}
                />
                <span />
                &nbsp;&nbsp;&nbsp;Do Not Show Fee
              </label>
            </div>

            <button className="btn btn-primary mt-4" onClick={handleShow}>
              Show
            </button>

            {reportData.campus && (
              <>
                <ExportIt reportData={reportData} />
                <ReactToPrint
                  documentTitle={`Check List Report`}
                  pageStyle={pageStyle}
                  onAfterPrint={handleAfterPrint}
                  onBeforeGetContent={handleOnBeforeGetContent}
                  onBeforePrint={handleBeforePrint}
                  removeAfterPrint={true}
                  trigger={() => (
                    <button
                      // onClick={() => console.log("dfsdfsdfd")}
                      disabled={printloading ? true : false}
                      className="btn btn-success button_blue button_blue mx-2 mt-4"
                    >
                      {printloading ? <span>Printing..</span> : "Print"}
                    </button>
                  )}
                  content={() => componentRef.current}
                />
              </>
            )}
          </div>
        </div>

        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner class="text-center" variant="info" animation="border" />
          </div>
        ) : (
          <div id="tableboot">
            <div className="row p-3 mt-5">
              <img
                // className='col-md-2'
                style={{ height: 110, width: 110 }}
                src={icon}
              />

              <div className="col-md-6">
                <h1>{unitName}</h1>
                <h6>
                  LIST OF STUDENT FOR THE ACADEMIC SESSION {fullSession.year}{" "}
                  {""}
                </h6>
              </div>
            </div>
            <ColoredLine color={"#FF0000"} />

            {reportData.campus && (
              <Table responsive>
                <thead>
                  <tr id="tbl">
                    <th>Sr.</th>
                    <th>Adm-Id</th>
                    <th>Student Name</th>
                    <th>Father Name</th>
                    <th>Mobile No</th>
                    <th>Phone No</th>
                    <th>Father's CNIC</th>
                    <th>DOB</th>
                    <th>Gender</th>
                    <th>Monthly Fee</th>
                    <th>Address</th>
                  </tr>
                </thead>

                <tbody>
                  <div style={{ height: "40px", position: "relative" }}>
                    <p
                      style={{
                        color: "black",
                        width: "200px",
                        fontWeight: "bold",
                        fontSize: "15px",
                        position: "absolute",
                        left: "80px",
                      }}
                      className="text_align_start"
                    >
                      {reportData.campus}
                    </p>
                  </div>
                  {reportData.campus_data.map((item) =>
                    item.sections.map(
                      (subitem) =>
                        subitem?.students.length > 0 && (
                          <>
                            <div
                              style={{
                                height: "40px",
                                display: "flex",
                                position: "relative",
                              }}
                            >
                              <p
                                style={{
                                  color: "black",
                                  fontWeight: "bold",
                                  fontSize: "15px",
                                  position: "absolute",
                                  left: "70px",
                                }}
                                className="text-center"
                              >
                                {" "}
                                Class:
                              </p>
                              <p
                                style={{
                                  color: "black",
                                  fontWeight: "bold",
                                  fontSize: "15px",
                                  position: "absolute",
                                  left: "150px",
                                  width: "400px",
                                  display: "flex",
                                }}
                                className="text-center"
                              >
                                {item.Class.name}
                              </p>
                              <p
                                style={{
                                  color: "black",
                                  fontWeight: "bold",
                                  fontSize: "15px",
                                  position: "absolute",
                                  left: "350px",
                                }}
                                className="text_align_start"
                              >
                                Section:
                              </p>
                              <p
                                style={{
                                  color: "black",
                                  fontWeight: "bold",
                                  fontSize: "15px",
                                  position: "absolute",
                                  left: "450px",
                                  width: "300px",
                                }}
                                className="text_align_start"
                              >
                                {subitem.section}
                              </p>
                            </div>
                            {subitem.students.map((student, i) => (
                              <tr id="tbl">
                                <td
                                  style={{ color: "black" }}
                                  className="text_align_start"
                                >
                                  {(num += 1)}
                                </td>
                                <td
                                  style={{ color: "black" }}
                                  className="text_align_start"
                                >
                                  {student.admission_id}
                                </td>
                                <td
                                  style={{ color: "black" }}
                                  className="text_align_start"
                                >
                                  {student.name}
                                </td>
                                <td
                                  style={{ color: "black" }}
                                  className="text_align_start"
                                >
                                  {student.father_name}
                                </td>
                                <td
                                  style={{ color: "black" }}
                                  className="text_align_start"
                                >
                                  {student.mobile_no}
                                </td>
                                <td
                                  style={{ color: "black" }}
                                  className="text_align_start"
                                >
                                  {student.phone}
                                </td>
                                <td
                                  style={{ color: "black" }}
                                  className="text_align_start"
                                >
                                  {student.father_cnic}
                                </td>
                                <td
                                  style={{ color: "black" }}
                                  className="text_align_start"
                                >
                                  {student.dob}
                                </td>
                                <td
                                  style={{ color: "black" }}
                                  className="text_align_start"
                                >
                                  {student.gender}
                                </td>
                                <td
                                  style={{ color: "black" }}
                                  className="text_align_start"
                                >
                                  {student?.monthly_fees != 0
                                    ? student?.monthly_fees
                                    : null}
                                </td>
                                <td
                                  style={{ color: "black" }}
                                  className="text_align_start"
                                >
                                  {student.address}
                                </td>
                              </tr>
                            ))}
                          </>
                        )
                    )
                  )}
                </tbody>
              </Table>
            )}
            {grandTotal > 0 && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                  columnGap: "20px",
                  paddingRight: "200px",
                }}
              >
                <p
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  Grand Total of Monthly Fee:-
                </p>
                <p
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  {grandTotal}
                </p>
              </div>
            )}
          </div>
        )}

        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint marginLeftTolegal"
        >
          <div id="tableboot" style={{ padding: "0px 10px" }}>
            <div className="p-3" style={{ display: "flex" }}>
              <img
                // className='col-md-2'
                style={{ height: 100, width: 100 }}
                src={icon}
              />

              <div className="col-md-6" style={{ paddingTop: "10px" }}>
                <h1>{unitName}</h1>
                <h6>
                  LIST OF STUDENT FOR THE ACADEMIC SESSION {fullSession.year}{" "}
                  {""}
                </h6>
              </div>
            </div>
            <ColoredLine color={"#FF0000"} />

            {reportData.campus && (
              <table style={{ width: "100%" }}>
                <thead>
                  <tr id="tbl" style={{ fontSize: "13px" }}>
                    <td style={{ color: "black", textAlign: "start" }}>
                      <b style={{ borderBottom: "1px solid black" }}>Sr.</b>
                    </td>
                    <td style={{ color: "black" }}>
                      <b style={{ borderBottom: "1px solid black" }}>Adm-Id</b>
                    </td>
                    <td style={{ textAlign: "start", color: "black" }}>
                      <b style={{ borderBottom: "1px solid black" }}>
                        Student Name
                      </b>
                    </td>
                    <td style={{ textAlign: "start", color: "black" }}>
                      <b style={{ borderBottom: "1px solid black" }}>
                        Father Name
                      </b>
                    </td>
                    <td style={{ color: "black", textAlign: "start" }}>
                      <b style={{ borderBottom: "1px solid black" }}>
                        Mobile No
                      </b>
                    </td>
                    <td style={{ color: "black", textAlign: "start" }}>
                      <b style={{ borderBottom: "1px solid black" }}>
                        Phone No
                      </b>
                    </td>
                    <td style={{ color: "black", textAlign: "start" }}>
                      <b style={{ borderBottom: "1px solid black" }}>
                        Father's CNIC
                      </b>
                    </td>
                    <td style={{ color: "black" }}>
                      <b style={{ borderBottom: "1px solid black" }}>DOB</b>
                    </td>
                    <td style={{ color: "black" }}>
                      <b style={{ borderBottom: "1px solid black" }}>Gender</b>
                    </td>
                    <td style={{ color: "black" }}>
                      <b style={{ borderBottom: "1px solid black" }}>Fee</b>
                    </td>
                    <td style={{ color: "black" }}>
                      <b style={{ borderBottom: "1px solid black" }}>Address</b>
                    </td>
                  </tr>
                </thead>

                <tbody>
                  <div style={{ height: "20px", position: "relative" }}>
                    <p
                      style={{
                        color: "black",
                        width: "200px",
                        fontWeight: "bold",
                        fontSize: "15px",
                        position: "absolute",
                        left: "80px",
                      }}
                      className="text_align_start"
                    >
                      {reportData.campus}
                    </p>
                  </div>
                  {reportData.campus_data.map((item) =>
                    item.sections.map(
                      (subitem) =>
                        subitem?.students.length > 0 && (
                          <>
                            <div
                              style={{
                                height: "20px",
                                display: "flex",
                                position: "relative",
                              }}
                            >
                              <p
                                style={{
                                  color: "black",
                                  fontWeight: "bold",
                                  fontSize: "15px",
                                  position: "absolute",
                                  left: "70px",
                                }}
                                className="text-center"
                              >
                                {" "}
                                Class:
                              </p>
                              <p
                                style={{
                                  color: "black",
                                  fontWeight: "bold",
                                  fontSize: "15px",
                                  position: "absolute",
                                  left: "150px",
                                  width: "400px",
                                  display: "flex",
                                }}
                                className="text-center"
                              >
                                {item.Class.name}
                              </p>
                              <p
                                style={{
                                  color: "black",
                                  fontWeight: "bold",
                                  fontSize: "15px",
                                  position: "absolute",
                                  left: "350px",
                                }}
                                className="text_align_start"
                              >
                                Section:
                              </p>
                              <p
                                style={{
                                  color: "black",
                                  fontWeight: "bold",
                                  fontSize: "15px",
                                  position: "absolute",
                                  left: "450px",
                                  width: "300px",
                                }}
                                className="text_align_start"
                              >
                                {subitem.section}
                              </p>
                            </div>
                            {subitem.students.map((student, i) => (
                              <tr
                                id="tbl managingtrHeight"
                                style={{
                                  borderBottom: "1px solid black",
                                  fontSize: "13px",
                                }}
                              >
                                <td
                                  style={{ color: "black", minWidth: "50px" }}
                                  className="text_align_start checklistlinehighter"
                                >
                                  {(num2 += 1)}
                                </td>
                                <td
                                  style={{ color: "black", width: "70px" }}
                                  className="text_align_start checklistlinehighter"
                                >
                                  {student.admission_id}
                                </td>
                                <td
                                  style={{ color: "black", width: "250px" }}
                                  className="text_align_start checklistlinehighter"
                                >
                                  {student.name.length > 25
                                    ? student.name.slice(0, 25)
                                    : student.name}
                                </td>
                                <td
                                  style={{ color: "black", width: "250px" }}
                                  className="text_align_start checklistlinehighter"
                                >
                                  {student.father_name.length > 25
                                    ? student.father_name.slice(0, 25)
                                    : student.father_name}
                                </td>
                                <td
                                  style={{
                                    color: "black",
                                    paddingRight: "10px",
                                  }}
                                  className="text_align_start checklistlinehighter"
                                >
                                  {student.mobile_no}
                                </td>
                                <td
                                  style={{
                                    color: "black",
                                    paddingRight: "10px",
                                  }}
                                  className="text_align_start checklistlinehighter"
                                >
                                  {student.phone}
                                </td>
                                <td
                                  style={{ color: "black", width: "150px" }}
                                  className="text_align_start checklistlinehighter"
                                >
                                  {student.father_cnic}
                                </td>
                                <td
                                  style={{ color: "black" }}
                                  className="text_align_start checklistlinehighter checkDobheight"
                                >
                                  {student.dob}
                                </td>
                                <td
                                  style={{ color: "black" }}
                                  className="text_align_start checklistlinehighter"
                                >
                                  {student.gender}
                                </td>
                                <td
                                  style={{ color: "black" }}
                                  className="text_align_start checklistlinehighter"
                                >
                                  {student?.monthly_fees != 0
                                    ? student?.monthly_fees
                                    : null}
                                </td>
                                <td
                                  style={{ color: "black" }}
                                  className="text_align_start checkAddress"
                                >
                                  {student.address}
                                </td>
                              </tr>
                            ))}
                          </>
                        )
                    )
                  )}
                </tbody>
              </table>
            )}
            {grandTotal > 0 && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                  columnGap: "20px",
                  paddingRight: "200px",
                  paddingTop: "20px",
                }}
              >
                <p
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  Grand Total of Monthly Fee:-
                </p>
                <p
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  {grandTotal}
                </p>
              </div>
            )}
          </div>
        </div>
      </Paper>
    </>
  );
};

export default CheckList;
const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 5,
    }}
  />
);
