import { Paper } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { baseUrl } from "../../../services/config";
import { useAlert } from "react-alert";
import { Spinner, Table } from "react-bootstrap";
import { Divider } from "antd";
import ReactToPrint from "react-to-print";
import { upperCase } from "lodash";
import { DownloadTableExcel } from "react-export-table-to-excel";

const FeeeRecievedMonth = () => {
  const [feeTypes, setFeeTypes] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [sessionData, setSessionData] = useState([]);
  const [yearId, setYearId] = useState("");
  const [feeTypeId, setFeeTypeId] = useState("");
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);
  let authtoken = localStorage.getItem("userToken");
  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const tableRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);
  const alert = useAlert();

  useEffect(() => {
    getSettingData();
    sessions();
    getFeeTypes();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sessions = async () => {
    await fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setYearId(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFeeTypes = async () => {
    await fetch(`${baseUrl}/feestypes`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "======");
        setFeeTypes(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };
  const handleShow = () => {
    if (feeTypeId == "") {
      alert.error("Fee Type is required");
      return;
    }
    if (date == "") {
      alert.error("Date is required");
      return;
    }
    const data = {
      year_id: yearId,
      fees_type_id: feeTypeId.id,
      date: date,
    };
    setLoading(true);
    fetch(`${baseUrl}/student-fee-received-month-wise-report`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data.payload) {
          setReportData(data.payload);
        }
        console.log("this is data", data);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err is re", err);
      });
  };
  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  const getMonthName = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const d = new Date(date);

    return `${monthNames[d.getMonth()]}-${d.getFullYear()}`;
  };
  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-md-3">
            <label>
              <b>Select Session</b>
            </label>
            <div className="d-flex flex-column ">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                value={yearId}
                aria-label="Default select example"
                onChange={(e) => setYearId(e.target.value)}
              >
                <option value=""> Select Session</option>
                {sessionData &&
                  sessionData.map((item) => (
                    <option value={item.id}>{item.year}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Fee Type</b>
            </label>
            <div className="d-flex flex-column ">
              <select
                style={{ height: 40 }}
                className="form-select"
                aria-label="Default select example"
                onChange={(e) => setFeeTypeId(JSON.parse(e.target.value))}
              >
                <option value="">Select Fee Type</option>
                {feeTypes &&
                  feeTypes.map((item) => (
                    <option value={JSON.stringify(item)}>{item.name}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-3 ">
            <label>
              <b>Select Date</b>
            </label>
            <input
              type="date"
              className="form-control"
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
          <div className="col-md-3 mt-9">
            <button
              disabled={loading}
              className="btn btn-primary"
              onClick={handleShow}
            >
              Show
            </button>
            {reportData?.length > 0 ? (
              <>
                <ReactToPrint
                  pageStyle={"auto"}
                  documentTitle={`Fee Recieved Month Wise`}
                  onAfterPrint={handleAfterPrint}
                  onBeforeGetContent={handleOnBeforeGetContent}
                  onBeforePrint={handleBeforePrint}
                  removeAfterPrint={true}
                  trigger={() => (
                    <button
                      // onClick={() => console.log("dfsdfsdfd")}
                      disabled={printloading ? true : false}
                      className="btn btn-primary w-100px mx-2"
                    >
                      {printloading ? <span>Printing..</span> : "Print"}
                    </button>
                  )}
                  content={() => componentRef.current}
                />

                <DownloadTableExcel
                  filename="Fee Recieved Month Wise"
                  sheet="Fee Recieved Month Wise"
                  currentTableRef={tableRef.current}
                >
                  <button className="btn btn-primary mx-2">Export</button>
                </DownloadTableExcel>
              </>
            ) : null}
          </div>
        </div>
        <Divider />

        <>
          {reportData.length > 0 ? (
            <div className="row mt-9">
              <img
                style={{ height: 100, width: 120, paddingLeft: "20px" }}
                src={icon}
                alt="logo"
              />
              <div className="col-md-8">
                <h1>{unitName}</h1>
                <h3>
                  Fee Receiving Detail For The Month Of {getMonthName(date)}{" "}
                  {feeTypeId.name}
                </h3>
              </div>
            </div>
          ) : (
            ""
          )}
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner class="text-center" variant="info" animation="border" />
            </div>
          ) : (
            <div id="tableboot">
              <Table responsive>
                <thead>
                  <tr id="tbl">
                    <th>Sr.No</th>
                    <th>Adm-Id</th>
                    <th>Name</th>
                    <th>Class Name</th>
                    <th>Campus Name</th>
                    <th>Amount</th>
                  </tr>
                </thead>

                <tbody>
                  {reportData &&
                    reportData.map((item, index) => (
                      <tr id="tbl">
                        <td style={{ color: "black" }} className="text=center">
                          {index + 1}
                        </td>

                        <td style={{ color: "black" }} className="text=center">
                          {item.admission_id}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_start"
                        >
                          {item.name}
                        </td>
                        <td style={{ color: "black" }} className="text=center">
                          {item.class}
                        </td>
                        <td style={{ color: "black" }} className="text=center">
                          {item.campus}
                        </td>
                        <td style={{ color: "black" }} className="text=center">
                          {item.amount}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          )}
        </>

        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          {reportData.length > 0 ? (
            <>
              <div className="d-flex mt-9">
                <div>
                  <img
                    style={{ height: 100, width: 120, paddingLeft: "20px" }}
                    src={icon}
                    alt="logo"
                  />
                </div>
                <div className="col-md-8">
                  <h1>{unitName}</h1>
                  <h3>
                    Fee Receiving Detail For The Month Of {getMonthName(date)}{" "}
                    {feeTypeId.name}
                  </h3>
                </div>
              </div>
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner
                    class="text-center"
                    variant="info"
                    animation="border"
                  />
                </div>
              ) : (
                <div id="tableboot">
                  <Table responsive>
                    <thead>
                      <tr id="tbl">
                        <th style={{ fontSize: "18px", color: "black" }}>
                          Sr.No
                        </th>
                        <th style={{ fontSize: "18px", color: "black" }}>
                          Adm-Id
                        </th>
                        <th style={{ fontSize: "18px", color: "black" }}>
                          Name
                        </th>
                        <th style={{ fontSize: "18px", color: "black" }}>
                          Class Name
                        </th>
                        <th style={{ fontSize: "18px", color: "black" }}>
                          Campus Name
                        </th>
                        <th style={{ fontSize: "18px", color: "black" }}>
                          Amount
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {reportData &&
                        reportData.map((item, index) => (
                          <tr
                            id="tbl"
                            style={{ fontSize: "16px", color: "black" }}
                          >
                            <td
                              style={{ color: "black" }}
                              className="text-center"
                            >
                              {index + 1}
                            </td>

                            <td
                              style={{ color: "black" }}
                              className="text=center"
                            >
                              {item.admission_id}
                            </td>
                            <td
                              style={{ color: "black" }}
                              className="text_align_start"
                            >
                              {item.name}
                            </td>
                            <td
                              style={{ color: "black" }}
                              className="text=center"
                            >
                              {item.class}
                            </td>
                            <td
                              style={{ color: "black" }}
                              className="text=center"
                            >
                              {item.campus}
                            </td>
                            <td
                              style={{ color: "black" }}
                              className="text=center"
                            >
                              {item.amount}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              )}
            </>
          ) : (
            ""
          )}
        </div>

        {/* For Export */}
        <div
          ref={tableRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          {reportData.length > 0 ? (
            <>
              <div className="d-flex mt-9">
                <div className="col-md-8">
                  <h1>{unitName}</h1>
                  <h3>
                    Fee Receiving Detail For The Month Of {getMonthName(date)}{" "}
                    {feeTypeId.name}
                  </h3>
                </div>
              </div>
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner
                    class="text-center"
                    variant="info"
                    animation="border"
                  />
                </div>
              ) : (
                <div id="tableboot">
                  <Table responsive>
                    <thead>
                      <tr id="tbl">
                        <th style={{ fontSize: "18px", color: "black" }}>
                          Sr.No
                        </th>
                        <th style={{ fontSize: "18px", color: "black" }}>
                          Adm-Id
                        </th>
                        <th style={{ fontSize: "18px", color: "black" }}>
                          Name
                        </th>
                        <th style={{ fontSize: "18px", color: "black" }}>
                          Class Name
                        </th>
                        <th style={{ fontSize: "18px", color: "black" }}>
                          Campus Name
                        </th>
                        <th style={{ fontSize: "18px", color: "black" }}>
                          Amount
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {reportData &&
                        reportData.map((item, index) => (
                          <tr
                            id="tbl"
                            style={{ fontSize: "16px", color: "black" }}
                          >
                            <td
                              style={{ color: "black" }}
                              className="text-center"
                            >
                              {index + 1}
                            </td>

                            <td
                              style={{ color: "black" }}
                              className="text=center"
                            >
                              {item.admission_id}
                            </td>
                            <td
                              style={{ color: "black" }}
                              className="text_align_start"
                            >
                              {item.name}
                            </td>
                            <td
                              style={{ color: "black" }}
                              className="text=center"
                            >
                              {item.class}
                            </td>
                            <td
                              style={{ color: "black" }}
                              className="text=center"
                            >
                              {item.campus}
                            </td>
                            <td
                              style={{ color: "black" }}
                              className="text=center"
                            >
                              {item.amount}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </Paper>
    </>
  );
};

export default FeeeRecievedMonth;
